.box{

    &_business{
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 20px !important;
    }

    &_container{
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-gap: 20px;

        &-box{
            height: 108px;
            background: #FFFFFF;
            box-shadow: 0px 13.3657px 44.5523px rgba(160, 163, 189, 0.5);
            border-radius: 8.91046px;
            display: flex;
            align-items: center;
            padding: 15px;
            @media (max-width: 1380px) {
                height: 88px;
            }
            @media (max-width: 1260px) {
                padding: 8px;
            }

            &-image{
                width: 45px;
                height: 45px;
                border-radius: 50%;
                background-color: rgb(233, 236, 254);
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &-details{
                padding: 20px 0 0 10px;
                width: 100%;
                @media (max-width: 1380px) {
                    padding: 10px 0 0 8px;
                }
                h5{
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 21px;
                    color: #171717;
                    margin-bottom: 5px;
                    @media (max-width: 1380px) {
                        font-size: 15px;
                    }
                }
                p{
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    color: #6E6E6E;
                    margin-bottom: 5px;
                    @media (max-width: 1380px) {
                        font-size: 11px;
                    }

                    .percent{
                        font-weight: 400;
                        font-size: 12.4746px;
                        line-height: 15px;
                        color: #4BDE97;
                        margin-right: 10px;
                        font-size: 12.4746px;
                        @media (max-width: 1380px) {
                            font-size: 10px;
                        }
                    }
                    .percentRed{
                        font-weight: 400;
                        font-size: 12.4746px;
                        line-height: 15px;
                        color: #FA582E;
                        margin-right: 10px;
                        @media (max-width: 1380px) {
                            font-size: 10px;
                        }
                    }
                    .small-text{
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        color: #B9B9B9;
                        @media (max-width: 1380px) {
                            font-size: 10px;
                        }
                        
                    }
                }
            }
        }
        &-thirdParty{
            width: 210px;
            height: 108px;
            background: #FFFFFF;
            box-shadow: 0px 13.3657px 44.5523px rgba(160, 163, 189, 0.5);
            border-radius: 8.91046px;
            align-items: center;
            padding: 10px;
            margin: 0 30px 30px 0;


            &-image{
                width: 53.67px;
                height: 53.67px;
                border-radius: 50%;
                background-color: rgb(240, 248, 255);
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &-details{
                padding: 10px 10px 10px 10px;
                h6{
                    font-weight: 700;
                    font-size: 14.7882px;
                    line-height: 21px;
                    color: #171717;
                    margin-bottom: 25px;
                }
                h5{
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 21px;
                    color: #171717;
                    margin-bottom: 5px;
                }
                p{
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    color: #6E6E6E;
                    margin-bottom: 5px;

                    .percent{
                        font-weight: 400;
                        font-size: 12.4746px;
                        line-height: 15px;
                        color: #4BDE97;
                        margin-right: 10px;
                    }
                    .small-text{
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        color: #B9B9B9;
                    }
                }
            }
        }
    }
}