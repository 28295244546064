.select{
    position: relative;
    width: 70%;

    &_box{
        width: 100%;
        // height: 48px;
        padding: 7px 10px;
        font-size: 15px;
        border: 1px solid #0046E6;
        border-radius: 5px;
        color: $color-formInput;
        margin: 10px 0;

        // &:focus{
        //     border: 2px solid $color-primary;
        // }
        &:active{
            border: 2px solid $color-primary;
        }
    }

    &_options{
        width: 100%;
        max-height: 500px;
        overflow-y: scroll;
        position: absolute;
        top: 50px;
        border-radius: 5px;
        left: 0;
        right: 0;
        padding: 10px 10px;
        background-color: white;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.09);
        z-index: 2;
        p{
            padding: 5px 5px;
            border-radius: 4px;
            margin: 0 !important;
            font-weight: 600;
            font-size: 13px;
            line-height: 24px;
            color: #303030;
            &:hover{
                background: #e8e7e7;
                cursor: pointer;
            }
        }

        &_selected{
            background: rgba(43, 78, 158, 0.09);
        }
    }

    &_arrow{
        position: absolute;
        top: 45%;
        right: -15px;
        pointer-events: none;
        &-type{
            position: absolute;
            top: 28px;
            right: 15px;
            pointer-events: none;
        }
    }
}

.selected{
    position: relative;
    width: 70%;

    &_box{
        width: 100%;
        height: 48px;
        padding: 10px 30px;
        font-size: 15px;
        border: 1px solid #DADADA;
        border-radius: 5px;
        color: $color-formInput;
        margin: 10px 0;

        // &:focus{
        //     border: 2px solid $color-primary;
        // }
        &:active{
            border: 2px solid $color-primary;
        }
    }

    &_options{
        width: 100%;
        max-height: 500px;
        overflow-y: scroll;
        position: absolute;
        top: 50px;
        border-radius: 5px;
        left: 0;
        right: 0;
        padding: 10px 10px;
        background-color: white;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.09);
        z-index: 2;
        p{
            padding: 5px 5px;
            border-radius: 4px;
            margin: 0 !important;
            font-weight: 600;
            font-size: 13px;
            line-height: 24px;
            color: #303030;
            &:hover{
                background: #e8e7e7;
                cursor: pointer;
            }
        }

        &_selected{
            background: rgba(43, 78, 158, 0.09);
        }
    }

    &_arrow{
        position: absolute;
        top: 45%;
        right: 12px;
        pointer-events: none;
        &-type{
            position: absolute;
            top: 28px;
            right: 15px;
            pointer-events: none;
        }
    }
}