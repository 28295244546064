.credit{
    margin: 0 30px 50px;

    &-bvn{
        margin: 0 0 50px;
        position: relative;
        &-label{
            color: #8A8A8A;
            margin: 50px 0 10px;
        }
        &-input{
            width: 300px;
            height: 40px;
            border: 1px solid #DADADA;
            border-radius: 4px;
            margin-right: 10px;
            padding: 3px 15px;
            color: #8A8A8A;

            &-show{
                bottom: 12px;
                left: 300px;
                position: absolute;
                right: 20px;
                width: 20px;
                cursor: pointer;
            }
        }
    

        button{
            background: $color-sidebar ;
            border: 1px solid $color-sidebar;
            border-radius: 5px;
            height: 40px;
            width: 90px;
            text-align: center;
            color: white;
        }
    }

    &_classic{
        margin: 50px 0 0;
    }

    &_fico{
        background: #FFFFFF;
        border: 0.15px solid #4C8BF5;
        box-sizing: border-box;
        box-shadow: 0px 4px 90px rgba(163, 171, 185, 0.24);
        border-radius: 15px;
        margin: 0 0 30px;

        hr{
            border: 0.5px solid #0046E6;
        }

        &-single{
            padding: 15px;

            &-img{
                background: #0046E6;
                width: 50px;
                height: 50px;
                margin: 0 0 10px;
                border-radius: 50%;
                img{
                    width: 35px;
                    margin: 7px 0 0 8px;
                }
            }

            &-top{
                img{
                    width: 30px;
                    margin: -15px 10px 0 0;
                }
                p{
                    font-size: 1em;
                    font-weight: 700;
                    opacity: 0.5;
                    margin: 0 !important;
                }
            }

            &-text{
                font-size: 1.8em;
                font-weight: 700;
                margin: 0 !important;
            }

            &-flex{
                display: flex;
                .average{
                    color: #FF8B03;
                }
                .good{
                    color: #47D800;
                }
                .bad{
                    color: #FF2608;
                }

                .more{
                    margin: 7px  0 0 auto;
                    font-weight: 700;
                    font-size: 1em;
                    opacity: 0.5;
                    cursor: pointer;
                    img{
                        margin: -15px 0 0 10px;
                    }
                }
            }

            &-reasons{
                font-size: 1em;
                opacity: 0.5;
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.6s ease-out;
            }
        }

        &-wrapper{
            border-radius: 10px;
            margin: 15px 0 ;
            padding: 0 !important;
            border: 2px solid #0046E6;
        }

        &-chart{
            &-text{
                display: flex;
                align-items: center;
                p{
                    margin: 0 0 0 10px;
                    font-weight: 700;
                    font-size: 1.3em;
                    color: #4C8BF5;
                }
            }

            &-main{
                // border: 1px solid red;
                position: relative;
                margin-top: 50px;
                overflow: hidden;

                .chart{
                    width: 90%;
                    display: block;
                    margin: auto;
                }
                .guage{
                    border-width: 0px;
                    height: 4px;
                    width: 60%;
                    left: 20%;
                    background-color: black;
                    position: absolute;
                    // transform: rotate(90deg);
                    background-color: #000000;
                    background-image: linear-gradient(270deg, #ffffff 50%, #000000 50%);

                    bottom: 5px;
                    border-radius: 15px;
                }
            }
        }
    }

    &_boxes{
        box-sizing: border-box;
        margin: 30px 20px 30px 0;

        &-title{
            margin: 0 0 40px;
            @media(min-width: $desktop){
                display: flex;
            }

            span{
                font-size: 15px;
                margin-left: 10px;
                font-weight: 400;
            }

            &-buttons{
                margin-left: auto;
                button{
                    border: 1px solid #0046E6;
                    box-sizing: border-box;
                    border-radius: 7px;
                    padding: 5px 15px;
                    font-size: 1em;
                    background: #FFFFFF;
                    img{
                        width: 20px;
                        margin: -3px 4px 0 0;
                    }
                }

                .share{
                    margin: 0 10px 0 0;
                }
            }
        }

        &-single{
            background: #FFFFFF;
            border: 0.15px solid #4C8BF5;
            box-sizing: border-box;
            box-shadow: 0px 4px 90px rgba(163, 171, 185, 0.24);
            border-radius: 15px;
            padding: 15px;
            margin: 0 0 30px;

            &-top{
                display: flex;
                img{
                    width: 30px;
                    margin: -15px 10px 0 0;
                }
                p{
                    font-size: 1em;
                    font-weight: 700;
                    opacity: 0.5;
                }
            }

            &-text{
                font-size: 1.3em;
                margin: 0 !important;
            }
        }
    }

    &_checkTable{
        width: 100%;

        @media(max-width: $tab-land){
            overflow-x: scroll;
        }

        h5{
            background: #D0D0D0;
            border: 1px solid #D0D0D0;
            margin: 0 0 20px 0;
        }
        p{
            font-size: 20px;
            font-weight: 500
        }
        table{
            width: 100%;
            margin-bottom: 30px;

            th,td{
                border-right: 0.5px solid #DADADA;
                padding: 0 5px !important;
            }
            @media(max-width: $tab-land){
                width: 800px;
            }
            tr{
                border-bottom: 1px solid #DADADA;
            }
            th,td{
                padding: 10px 5px !important;
            }

            .open{
                font-size: 0.9em;
                border: 1px solid #DB8C28;
                color: #DB8C28;
                font-weight: 600;
                border-radius: 5px;
                background: transparent;
                text-align: center;
                padding: 2px 10px;
            }

            .closed{
                font-size: 0.9em;
                border: 1px solid #7FC008;
                color: #7FC008;
                font-weight: 600;
                border-radius: 5px;
                background: transparent;
                text-align: center;
                padding: 2px 10px;
            }
        }

        .view{
            border: 1px solid #47D800;
            background-color: #47D800;
            border-radius: 4px;
            margin-right: 10px;
            color: white;
            width: 100px;
        }
    }

    &_enquiry{
        &-topText{
            font-size: 1.2em;
        }
        
        &-data{
            border: 0.25px solid #0046E6;
            box-sizing: border-box;
            box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
            border-radius: 10px !important;
            max-width: 600px;
            margin: 0 0 40px;
            padding: 0 20px;

            table{
                width: 100%;
            }

            tr{
                border-bottom: 0.75px solid #B5B5B5;
                color: #0F123F;
                font-size: 1em;
    
                @media(max-width: $mobile){
                    font-size: 0.9em;
                }
            }
            th, td{
                padding: 10px 0;
            }
            th{
                opacity: 0.56;
                font-weight: 400;
                text-transform: capitalize !important;
            }

            .view{
                border: 1px solid #47D800;
                background-color: #47D800;
                border-radius: 4px;
                margin-right: 10px;
                color: white;
                width: 100px;
                img{
                    margin-bottom: 3px;
                    margin-right: 8px;
                }
            }
        }
    }
}