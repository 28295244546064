.approvals{
    width: 100%;
    height: 100%;

    &_nav{
        overflow-x: scroll;
        p{
            white-space: nowrap;
        }
    }
}