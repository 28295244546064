.accounts{
    box-sizing: border-box;
    &_name{
        margin-bottom: 50px;
        display: flex;
        &-tag{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: #CE06E0;
            position: relative;
            p{
                color: white;
                font-weight: 700;
                font-size: 36px;
                line-height: 47px;
                text-align: center;
                margin-top: 3px;
                text-transform: uppercase;
            }
        }
        
        h3{
            margin: 7px 0 0 15px;
            text-transform: capitalize;
        }
    }

    &_details{
        border: 0.25px solid #0046E6;
        box-sizing: border-box;
        box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
        border-radius: 10px;
        width: 589px;
        padding: 36px;
        margin-top: 50px;

        @media(max-width: $mobile){
            width: 100%;
        }

        &-top{
            p{
                margin-bottom: 0 !important;
            }
        }

        &-row{
            display: flex;
            // width: 90%;
            justify-content: space-between;
            align-items:flex-end;
            margin: 20px 0;
        }

        &-main{
            color: #6C6C6C;
            font-size: 1em;
            flex: 1;
            margin-right: 30px;
            .single{
                display: flex;
                .value{
                    margin-left: auto;
                }
            }

            .text1{
                margin: 0;
                font-weight: 400;
                font-size: 16px;
            }

            .text2{
                color: black !important;
                // text-transform: capitalize;
                font-weight: 500;
                font-size: 21px;
                line-height: 27px;
            }
        }
    }

    &_permission{
        border: 0.25px solid #0046E6;
        box-sizing: border-box;
        box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
        border-radius: 10px;
        width: 300px;
        padding: 20px;
        margin-top: 20px;

        p{
            
            span{
            background-color: aliceblue;
            padding: 4px 12px;
            border-radius: 10px;
            float: right;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            margin-top: -8px;
            color: #0046E6;
            cursor: pointer;
            }
        }

        @media(max-width: $mobile){
            width: 100%;
        }

        label{
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 10px;
        }

        button{
            margin-top: 10px;
            width: 100px;
            height: 40px;
            color: #FFFFFF;
            background: #0046E6;
            border-radius: 8px;
            border: 0;
            font-weight: 500;
        }

       
    }
}