.apis{
    margin: 50px 0px 0 20px;
    @media(max-width: 1350px){
        margin: 50px 0px 0;
    }
    
    @media(max-width: $mobile){
        margin: 0;
    }
    &_row1{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        justify-content: flex-start;

        .income1{
            @media(max-width: 1350px){
                display: none
            }
            @media(max-width: 650px){
                margin-top: 70px;
                display: initial;
            }

        }
        .income2{
            @media(min-width: 1350px){
                display: none
            }
            @media(max-width: 650px){
                display: none;
            }

        }

    }
    &_box{
        border: 0.5px solid #0046E6;
        box-sizing: border-box;
        box-shadow: 0.453849px 0.902061px 26.1391px 0.902061px rgba(0, 0, 0, 0.1);
        border-radius: 22px;
        padding: 15px;
        margin: 20px;
        min-height: 600px;

        &-1{
            width: 20%;
            @media(max-width: 1100px){
                width: 43%;
            }
            @media(max-width: 650px){
                width: 100%;
            }
        }

        &-2{
            margin: 0;
            box-sizing: border-box;
            display: flex;
            @media(max-width: 1100px){
                flex-wrap: wrap;
            }
        }

        &-title{
            text-align: center;
        }
        
        &-3{
            width: 20%;
            @media(max-width: 1100px){
                width: 43%;
            }
            @media(max-width: 650px){
                width: 100%;
            }

            h6{
                color: $color-sidebar;
                font-size: .78em;
            }

            &-topText{
                margin-bottom: 10px !important;
                font-size: 0.8em;
                color: $color-sidebar;
    
                span{
                    color: #B5AFAF;
                    font-weight: 700;
                    font-size: .68em;
                }
            }

            &-list{
                display: flex;
                align-items: flex-start;
                font-size: 0.8em;
    
                img{
                    width: 15px !important;
                    flex-shrink: 0;
                    margin: 5px 10px 0 0;
                }
            }
        }

        &-4{
            width: 70%;
            @media(max-width: 1350px){
                width: 100%;
            }
        }

        h5{
            color: $color-sidebar;
            font-size: 1.4em;
        }

        &-topText{
            margin-bottom: 10px !important;
            font-size: 0.9em;
            color: $color-sidebar;

            span{
                color: #B5AFAF;
                font-weight: 700;
                font-size: 1em;
            }
        }

        &-list{
            display: flex;
            align-items: flex-start;
            font-size: 0.7em;
            color: #B5AFAF;

            img{
                width: 17px !important;
                flex-shrink: 0;
                margin: 0px 10px 0 0;
            }
        }

        &-list1 {
            p {
            margin: 0 !important;

            }
            color: #B5AFAF;
            font-size: .6rem;
        }
    }
}