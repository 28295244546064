*,
*::after,
*::before {
  margin: 0px;
  padding: 0px;
  box-sizing: inherit;
}

html {
  overflow-x: hidden;
}

.u-padding-none {
  padding: 0;
}

.u-margin-none {
  margin: 0;
}

.compliance {
  margin-top: 30px;
}
@media (max-width: 560px) {
  .compliance {
    margin: 30px 20px 40px;
  }
}
.compliance_top {
  display: flex;
  margin: auto;
  justify-content: center;
}
.compliance_top-circle {
  background: linear-gradient(128.58deg, #0FCD16 14.67%, #2BBD35 86.8%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 30px;
}
@media (max-width: 560px) {
  .compliance_top-circle {
    display: none;
  }
}
.compliance_top-progress {
  border-top: 2px dashed #629C66;
  width: 300px;
  position: relative;
  margin-top: 20px;
}
@media (max-width: 560px) {
  .compliance_top-progress {
    width: 75%;
    margin: 20px auto;
  }
}
.compliance_top-progress img {
  width: 20px;
  position: absolute;
  top: -12px;
  background-color: white;
}
.compliance_top-progress p {
  position: absolute;
  font-size: 1em;
  color: #5A5A5A;
  top: 10px;
}
@media (max-width: 560px) {
  .compliance_top-progress p {
    font-size: 0.9em;
  }
}
.compliance_top-progress .icon1 {
  left: 0;
}
.compliance_top-progress .icon2 {
  left: 140px;
}
@media (max-width: 560px) {
  .compliance_top-progress .icon2 {
    left: calc(50% - 10px);
  }
}
.compliance_top-progress .icon3 {
  right: 0;
}
.compliance_top-progress .text1 {
  left: -10px;
}
.compliance_top-progress .text2 {
  left: 120px;
}
@media (max-width: 560px) {
  .compliance_top-progress .text2 {
    left: calc(50% - 30px);
  }
}
.compliance_top-progress .text3 {
  right: -40px;
}
@media (max-width: 560px) {
  .compliance_top-progress .text3 {
    right: -30px;
  }
}
.compliance_main {
  margin: 40px auto 0;
  display: block;
  padding: 20px;
  max-width: 500px;
  border-radius: 10px;
}
.compliance_save {
  width: 540px;
  padding: 10px 0;
  text-align: center;
  display: block;
  margin: 20px auto 30px;
  border-radius: 7px;
  border: 1px solid #0046E6;
  background: #0046E6;
  font-size: 1.1em;
  font-weight: 600;
  color: white;
}
@media (max-width: 560px) {
  .compliance_save {
    width: 100%;
  }
}
.compliance_profile-form {
  box-sizing: border-box;
}
.compliance_profile-form label {
  font-weight: 600;
  font-family: "Mulish", sans-serif;
  margin: 20px 0 5px;
}
.compliance_profile-form p, .compliance_profile-form label {
  color: #353535;
  font-size: 1em;
}
.compliance_profile-form input {
  width: 100%;
  height: 2.5em;
  padding: 5px 15px;
  border: 2px solid #DADADA;
  box-sizing: border-box;
  border-radius: 4px;
}
.compliance_profile-form input:focus {
  border: 2px solid #0046E6;
  outline: none;
}
.compliance_profile-info h6 {
  color: #5B5B5B;
  margin: 0 !important;
  font-size: 1em;
}
.compliance_profile-info p {
  color: #353535;
  font-size: 1em;
}
.compliance_profile-info button {
  background: #EAEAEA;
  border: 1px solid #696969;
  border-radius: 8px;
  text-align: center;
  padding: 8px 15px;
  width: 50px;
  font-weight: 700;
}
.compliance_profile-info-next {
  float: right;
}
.compliance_alldone {
  margin: 20px auto;
  text-align: center;
}

.home {
  padding-top: 20px;
}
.home_main {
  margin: 30px 40px;
}
.home_main-tableHeader {
  margin-top: 4em;
}
.home_main-topText {
  width: 100%;
}
.home_main-topText h5 {
  font-size: 1.2em;
  font-weight: 700;
  text-transform: capitalize;
}
.home_main-topText p {
  font-size: 1em;
}
.home_main-topText-search {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  flex-direction: column;
}
.home_main-topText-toggles {
  display: flex;
  margin-left: auto;
}
.home_main-topText-filter {
  border: 1px solid #D0D5DD;
  height: 40px;
  border-radius: 10px;
  padding: 6px 10px 0;
  margin-left: 10px;
  cursor: pointer;
}
.home_main-topText-filter img {
  margin: -2px 5px 0 0;
}
.home_main-topText-filter-options {
  position: absolute;
  top: 70px;
  right: 0;
  z-index: 2;
  background: white;
  border: 1px solid #EAECF0;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}
.home_main-topText-filter-options p {
  margin: 5px 10px;
  cursor: pointer;
}
.home_main-topText-filter-options hr {
  margin: 0;
  border: 1px solid #EAECF0;
}
.home_main h4 {
  font-weight: 700;
  font-size: 21px;
  line-height: 27px;
}
@media (max-width: 560px) {
  .home_main {
    margin: 30px;
  }
}
.home_main-dropdown {
  width: 150px;
  float: right;
  margin-top: -70px;
}
.home_main-overview {
  margin-top: 20px;
}
.home_main-overview-activity {
  background: #FFFFFF;
  box-shadow: 0px 13.3657px 44.5523px rgba(160, 163, 189, 0.5);
  border-radius: 8.91046px;
  padding: 15px;
  margin-top: 50px;
}
.home_main-overview-activity h3 {
  font-weight: 700;
  font-size: 21px;
  line-height: 27px;
}
.home_main-overview-topBiz {
  width: 600px;
  height: 450px;
  background: #FFFFFF;
  box-shadow: 0px 13.3657px 44.5523px rgba(160, 163, 189, 0.5);
  border-radius: 10.3284px;
  padding: 15px;
  overflow-y: scroll;
}
.home_main-overview-topBiz h3 {
  font-weight: 700;
  font-size: 21px;
  line-height: 27px;
}
.home_main-overview-topBiz p {
  color: #6E6E6E;
  font-weight: 500;
  font-size: 12.4746px;
  line-height: 16px;
}
.home_main-overview-topBank {
  width: 361px;
  background: #FFFFFF;
  box-shadow: 0px 13.3657px 44.5523px rgba(160, 163, 189, 0.5);
  border-radius: 10.3284px;
  padding: 15px;
}
.home_main-overview-topBank h3 {
  font-weight: 700;
  font-size: 21px;
  line-height: 27px;
}
.home_main-overview-topBank p {
  color: #6E6E6E;
  font-weight: 500;
  font-size: 13.055px;
  line-height: 17px;
}
.home_main-boxes {
  box-sizing: border-box;
  margin: 40px 0;
}
.home_main-boxes h6 {
  font-family: "Mulish", sans-serif !important;
  font-weight: 700;
  margin-bottom: 20px;
}
.home_main-boxes p {
  font: 1em;
}
.home_main-boxes-1 {
  border: 1px solid #0046E6;
  padding: 20px;
  border-radius: 10px;
  margin: 40px 0px 0;
  text-align: center;
  min-height: 300px;
  position: relative;
}
.home_main-boxes-1 button {
  width: 150px;
  position: absolute;
  bottom: 40px;
  left: calc(50% - 75px);
  border: 1px solid #0046E6;
  border-radius: 8px;
  background-color: transparent;
  padding: 8px 15px;
  text-align: center;
  font-weight: 500;
}
.home_main-boxes-1 button a {
  text-decoration: none;
  color: #000000;
}
.home_main-boxes-2 {
  border: 1px solid #0046E6;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  min-height: 340px;
  color: white;
  position: relative;
  background-color: #0046E6;
}
@media (max-width: 1200px) {
  .home_main-boxes-2 {
    margin: 40px 0px 0;
    min-height: 300px;
  }
}
.home_main-boxes-2 .top-button {
  width: 76px;
  height: 17.51px;
  font-size: 7.76972px;
  color: #fff;
  background: #FFC702;
  border-radius: 3.72549px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home_main-boxes-2 .top-button p {
  margin-bottom: none !important;
}
.home_main-boxes-2 img {
  background-color: white;
  border-radius: 50%;
  width: 50px;
  padding: 10px;
  margin: 20px 0 15px;
}
.home_main-boxes-2 button {
  width: 150px;
  position: absolute;
  bottom: 40px;
  left: calc(50% - 75px);
  border: 1px solid white;
  border-radius: 8px;
  background-color: white;
  padding: 8px 15px;
  text-align: center;
  font-weight: 500;
}
.home_main-wallet {
  border: 1px solid #0046E6;
  padding: 20px;
  border-radius: 10px;
  color: white;
  background-color: #0046E6;
  max-width: 400px;
}
.home_main-wallet p {
  margin: 0 !important;
}
.home_main-wallet h5 {
  font-size: 1.5em;
  margin: 0 0 30px;
}
.home_main-wallet-add {
  padding: 5px 15px;
  background: white;
  border: 1px solid white;
  border-radius: 5px;
}
.home_main-wallet-view {
  background: transparent;
  border-color: transparent;
  color: white;
  float: right;
}
.home_main-csv {
  display: flex;
  justify-content: flex-end;
  margin: -70px 40px 0 0;
}
.home_main-csv a {
  background: #F5F5F5;
  width: 100px;
  text-align: center;
  border: 1px solid #0046E6;
  color: #000000;
  padding: 5px 18px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  text-decoration: none;
  background: none;
}
.home_main-csv a img {
  margin-right: 5px;
}
.home_wallet {
  margin: 40px;
}
@media (max-width: 769px) {
  .home_wallet {
    margin: 15px 40px 15px 15px;
  }
}
.home_wallet-section1-total {
  display: flex;
  border: 0.75px solid #4C8BF5;
  box-sizing: border-box;
  box-shadow: -1px 4px 17px -11px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  padding: 20px;
  max-width: 250px;
}
.home_wallet-section1-total-img {
  background-color: #34A853;
  width: 35px !important;
  height: 35px !important;
  border-radius: 50%;
  margin: 8px 10px 0 0;
}
.home_wallet-section1-total-img img {
  width: 21px;
  margin: 7px 0 0 7px;
}
.home_wallet-section1-total-text p {
  margin: 0 !important;
}
.home_wallet-section1-total-text h4 {
  font-size: 1.3em !important;
  margin: 0 !important;
}
.home_wallet-section1-fund {
  padding: 10px 20px;
  margin: 20px 0;
  background: #0046E6;
  border: 1px solid #0046E6;
  border-radius: 6px;
  color: white;
}
.home_wallet-section1-fund img {
  width: 20px;
  margin: -3px 8px 0 0;
}
.home_wallet-section2-history {
  border: 0.75px solid #4C8BF5;
  width: 75%;
  border-radius: 15px;
  margin-top: 30px;
}
@media (max-width: 1200px) {
  .home_wallet-section2-history {
    width: 100%;
  }
}
.home_wallet-section2-history-topic {
  font-size: 1.3em;
  margin: 20px;
}
.home_wallet-section2-history-info {
  margin: 20px;
}
.home_wallet-section2-history-info-single {
  display: flex;
  align-items: flex-start;
}
@media (max-width: 560px) {
  .home_wallet-section2-history-info-single {
    margin-left: -20px;
  }
}
.home_wallet-section2-history-info-single img {
  margin: -2px 10px 0 0;
}
.home_wallet-section2-history-info-single .main {
  width: 100%;
}
@media (min-width: 992px) {
  .home_wallet-section2-history-info-single .main {
    display: flex;
  }
}
.home_wallet-section2-history-info-single .main p {
  margin: 0;
  color: #9EA2A9;
}
.home_wallet-section2-history-info-single .main-desc-topic {
  color: black !important;
  font-weight: 600;
}
.home_wallet-section2-history-info-single .main-amount {
  margin-left: auto;
}
.home_wallet-section2-history-info-single .main-amount-value {
  font-weight: 700;
}
@media (min-width: 992px) {
  .home_wallet-section2-history-info-single .main-amount-value {
    text-align: right;
  }
}
.home_wallet-section2-history-info-single .main-amount .Credit {
  color: #5BC044 !important;
}
.home_wallet-section2-history-info-single .main-amount .Debit {
  color: #FA582E;
}
.home_wallet-section2-fund {
  border: 0.75px solid #4C8BF5;
  width: 400px;
  border-radius: 15px;
  padding: 15px;
}
@media (max-width: 560px) {
  .home_wallet-section2-fund {
    width: 90%;
  }
}
.home_wallet-section2-fund h5 {
  font-size: 1.5em;
  font-weight: 700;
}
.home_wallet-section2-fund form {
  box-sizing: border-box;
}
.home_wallet-section2-fund form input {
  width: 100%;
  margin: 10px 0;
  padding: 5px 15px;
  height: 40px;
  border: 2px solid #C4C4C4;
  border-radius: 5px;
  color: #8A8A8A;
}
.home_wallet-section2-fund form button {
  margin: 20px 0 50px;
  padding: 10px 20px;
  width: 100%;
  background: #0046E6;
  border: 1px solid #0046E6;
  border-radius: 6px;
  color: white;
}
.home_wallet-topup-form {
  background: #FFFFFF;
  border: 1px solid #F2F4F7;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  padding: 20px;
  width: 400px;
  margin-bottom: 40px;
  border-radius: 15px;
  box-sizing: border-box;
}
.home_wallet-topup-form h4 {
  margin-bottom: 30px;
}
.home_wallet-topup-form-text1 {
  margin: -20px 0 15px;
  color: #475467;
}
.home_wallet-topup-form-withBtn {
  display: flex;
}
.home_wallet-topup-form-withBtn .btnActive {
  background: #0046e6;
  border: 1px solid #0046e6;
  border-radius: 7px;
  color: #fff;
  flex-shrink: 0;
  height: 40px;
  margin-left: 10px;
  padding: 7px 10px;
  white-space: nowrap;
}
.home_wallet-topup-form-withBtn .btnInactive {
  background: #d0d0d0;
  border: 1px solid #d0d0d0;
  border-radius: 7px;
  color: #fff;
  flex-shrink: 0;
  height: 40px;
  margin-left: 10px;
  padding: 7px 10px;
  white-space: nowrap;
}
.home_wallet-topup-form div {
  position: relative;
}
.home_wallet-topup-form label {
  font-weight: 500;
  color: #344054;
}
.home_wallet-topup-form input {
  width: 100%;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #667085;
  padding: 7px 15px;
  margin-bottom: 20px;
}
.home_wallet-topup-form-button {
  background: #1A5FFF;
  border: 1px solid #1A5FFF;
  color: white;
  font-weight: 600;
  padding: 7px 15px;
  border-radius: 10px;
}
.home_wallet-topup-table {
  background: #FFFFFF;
  border: 1px solid #F2F4F7;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 15px;
}
.home_wallet-topup-table-header {
  margin: 20px 20px 0;
}

.myModal_blurBg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: -50px;
  bottom: -50px;
  z-index: 3;
  background: rgba(4, 4, 4, 0.77);
}
.myModal_main {
  width: 400px;
  background: white;
  border-radius: 10px;
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* overflow-y: scroll; */
}
@media (max-width: 560px) {
  .myModal_main {
    width: 300px;
  }
}
.myModal_main_privacy {
  max-width: 400px;
  max-height: 600px;
  border: 1px solid #0046E6;
  background: white;
  border-radius: 10px;
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  margin: 10px;
  overflow-y: scroll;
}
.myModal_main_privacy .top {
  display: flex;
  justify-content: space-between;
}
.myModal_main_privacy .top h3 {
  padding: 20px;
}
.myModal_main_privacy .top h1 {
  font-weight: 600;
  font-size: 17.9141px;
  line-height: 31px;
  align-items: center;
  color: #00072B;
}
.myModal_main_privacy table {
  width: 350px !important;
  text-align: center;
}
.myModal_main-widget {
  margin: 20px;
}
.myModal_main-widget-header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.myModal_main-widget-header p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  margin: 0 20px 0 0;
  /* flex: 1.5; */
  text-align: start;
  width: 60%;
}
.myModal_main-widget-title {
  font-size: 1em;
  text-align: center;
  font-weight: 700;
}
.myModal_main-widget-upload {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  /* background: #E3E4E7; */
  padding: 4px 20px 4px 10px;
  width: 100%;
  margin: 30px 0;
  border-radius: 8px;
  border: 1px solid #E3E4E7;
}
.myModal_main-widget-upload img {
  width: 30px;
}
.myModal_main-widget-upload p {
  margin: 3px 0 0 10px;
}
.myModal_main-widget-share {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0;
}
.myModal_main-widget-share img {
  width: 75px;
  height: 75px;
  margin-bottom: 20px;
}
@media (max-width: 560px) {
  .myModal_main-widget-share img {
    width: 75px;
    height: 75px;
    margin-bottom: 10px;
  }
}
.myModal_main-widget-share h4 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 35px;
}
@media (max-width: 560px) {
  .myModal_main-widget-share h4 {
    font-size: 20px;
    line-height: 28px;
  }
}
.myModal_main-widget-share p {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}
@media (max-width: 560px) {
  .myModal_main-widget-share p {
    font-size: 12px;
    line-height: 20px;
  }
}
.myModal_main-widget-share-action {
  display: flex;
  justify-content: space-around;
}
.myModal_main-widget-share-action .cancel {
  background: #D0D0D0;
  border: 1px solid #D0D0D0;
  padding: 5px 20px;
  border-radius: 7px;
  color: white;
  margin-left: auto;
}
.myModal_main-widget-share-action .share {
  background: #0046E6;
  border: 1px solid #0046E6;
  padding: 5px 20px;
  border-radius: 7px;
  color: white;
}
.myModal_main-widget-share-kyc {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.myModal_main-widget-share-kyc button {
  background: #0046E6;
  border: 1px solid #0046E6;
  margin-left: auto;
  width: 100%;
  color: #fff;
  padding: 5px;
  border-radius: 7px;
}
.myModal_main-widget-share-kyc p {
  font-size: 16px;
  margin: 16px 0;
  color: #0046E6;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.5px;
  cursor: pointer;
}
@media (max-width: 560px) {
  .myModal_main-widget-share-kyc p {
    font-size: 12px;
    margin: 12px 0;
  }
}
.myModal_main-widget-share-kyc p a {
  text-decoration: none;
}
.myModal_main-widget-share-kyc .link {
  background: #0046E6;
  border: 1px solid #0046E6;
  padding: 8px 24px;
  border-radius: 7px;
  color: white;
  width: 200px !important;
  font-size: 12px;
}
.myModal_main-widget-share-icons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 15px 0;
}
.myModal_main-widget-share-tick {
  display: flex;
  align-items: center;
  justify-content: center;
}
.myModal_main-widget-share-tick .tick {
  width: 20px;
  height: 20px;
  margin: 0;
}
.myModal_main-widget-share-tick h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  margin: 0 10px;
}
.myModal_main-widget-form {
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 30px;
}
.myModal_main-widget-form input {
  width: 100%;
  margin: 10px 0;
  padding: 5px 15px;
  height: 40px;
  border: 2px solid #C4C4C4;
  border-radius: 5px;
  color: #8A8A8A;
}
.myModal_main-widget-action {
  display: flex;
}
.myModal_main-widget-action .form-submit {
  background: #0046E6;
  border: 1px solid #0046E6;
  margin-left: auto;
  width: 100%;
}
.myModal_main-widget-action button {
  padding: 5px 20px;
  border-radius: 7px;
  color: white;
}
.myModal_main-widget-action .cancel {
  background: #D0D0D0;
  border: 1px solid #D0D0D0;
}
.myModal_main-widget-action .publish {
  background: #0046E6;
  border: 1px solid #0046E6;
  margin-left: auto;
}
.myModal_main-widget-action-close {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.myModal_main-widget-action-close button {
  padding: 5px 20px;
  border-radius: 7px;
  color: white;
}
.myModal_main-widget-action-close .close {
  background: #D0D0D0;
  border: 1px solid #D0D0D0;
  margin: 0 0 20px 0;
}
.myModal_main-widget-bottom {
  margin: 20px 0;
}
.myModal_main-linkBank-formflag {
  position: relative;
}
.myModal_main-linkBank-formflag img {
  position: absolute;
  bottom: 77px;
  width: 25px;
}
.myModal_main-linkBank-formflag input {
  padding: 5px 5px 5px 30px !important;
  box-sizing: border-box;
}
.myModal_main-linkBank input {
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid #757575;
  color: #757575;
  font-size: 1em;
  width: 100%;
  padding: 5px;
  margin: 30px 0;
}
.myModal_main-linkBank input:focus {
  outline: none;
}
.myModal_main-linkBank-toggle {
  display: flex;
  margin: 10px 0 30px;
}
.myModal_main-linkBank-toggle-btn {
  margin-left: auto;
}
.myModal_main-shareReport input {
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid #757575;
  color: #757575;
  font-size: 1em;
  width: 100%;
  padding: 5px;
  margin: 0 0 15px;
}
.myModal_main-shareReport input:focus {
  outline: none;
}
.myModal_main_password input {
  width: 100%;
  border: 1px solid #DADADA;
  border-radius: 4px;
  color: #757575;
  font-size: 1em;
  width: 100%;
  padding: 5px;
  margin: 5px 0 15px;
}
.myModal_main-delete {
  text-align: center;
  padding: 2em 1em 0 1em;
}
.myModal_main-delete h4 {
  margin-top: 20px;
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
}
.myModal_main-delete-top {
  padding: 1em 3em;
}
.myModal_main-delete-top label {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  margin-right: 15px;
}
.myModal_main-delete-bottom {
  padding: 1em 2em 2em 2em;
  display: flex;
  justify-content: space-between;
}
.myModal_main-delete-bottom .cancel {
  border: 1px solid #D0D0D0;
  padding: 5px 20px;
  color: #333333;
  background: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.5px;
  width: 112px;
}
.myModal_main-delete-bottom .publish {
  background: #D92D20;
  /* Error/600 */
  color: #fff;
  border: 1px solid #D92D20;
  /* Shadow/xs */
  width: 170px;
  height: 44px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.myModal_main-delete-bottom .proceed {
  background: #0046E6;
  border: 1px solid #0046E6;
  margin-left: auto;
  padding: 10px;
  border-radius: 7px;
  color: white;
  width: 112px;
}
.myModal_main-biz {
  text-align: center;
  padding: 2em 1em 0 1em;
}
.myModal_main-biz h4 {
  margin-top: 20px;
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
}
.myModal_main-biz-flex {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.myModal_main-biz-top {
  padding: 1em 3em;
}
.myModal_main-biz-top label {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  margin-right: 15px;
}
.myModal_main-biz-bottom {
  padding: 1em 2em 2em 2em;
  display: flex;
  justify-content: space-between;
}
.myModal_main-biz-bottom .cancel {
  border: 1px solid #D0D0D0;
  padding: 5px 20px;
  color: #333333;
  background: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.5px;
  width: 112px;
}
.myModal_main-biz-bottom .publish {
  background: #D92D20;
  /* Error/600 */
  color: #fff;
  border: 1px solid #D92D20;
  /* Shadow/xs */
  width: 170px;
  height: 44px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.myModal_main-biz-bottom .proceed {
  background: #0046E6;
  border: 1px solid #0046E6;
  margin-left: auto;
  padding: 10px;
  border-radius: 7px;
  color: white;
  width: 112px;
}
.myModal_mainInvite {
  width: 600px;
  background: white;
  border-radius: 10px;
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* overflow-y: scroll; */
}
@media (max-width: 560px) {
  .myModal_mainInvite {
    width: 300px;
  }
}
.myModal_mainInvite-invite h4 {
  margin-top: 20px;
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
}
.myModal_mainInvite-invite-top {
  padding: 1em 3em;
}
.myModal_mainInvite-invite-top label {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  margin-right: 15px;
}
.myModal_mainInvite-invite-bottom {
  padding: 0.7em 3em 3em 3em;
  display: flex;
  justify-content: space-between;
}
.myModal_mainInvite-invite-bottom .cancel {
  border: 1px solid #D0D0D0;
  padding: 5px 20px;
  color: #333333;
  background: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.5px;
  width: 112px;
}
.myModal_mainInvite-invite-bottom .publish {
  background: #0046E6;
  border: 1px solid #0046E6;
  margin-left: auto;
  padding: 12px 24px;
  border-radius: 7px;
  color: white;
}
.myModal_custom {
  width: 450px;
  background: white;
  border-radius: 10px;
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 560px) {
  .myModal_custom {
    width: 300px;
  }
}
.myModal_custom-invite h4 {
  margin: 20px;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
}
.myModal_custom-invite-top {
  padding: 0.8em;
}
.myModal_custom-invite-top label {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  margin-right: 15px;
}
.myModal_custom-invite-top_profile-form {
  padding: 2em 5em;
}
.myModal_custom-invite-top_profile-form-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.myModal_custom-invite-top_profile-form-div .input {
  height: 40px;
  border: 1px solid #DADADA;
  color: #8A8A8A;
  padding: 8px 10px;
  margin: 10px 0;
  border-radius: 7px;
  display: flex;
  align-items: center;
}
.myModal_custom-invite-top_profile-form-div .input input {
  border: none;
  width: 100%;
}
.myModal_custom-invite-top_profile-form label {
  font-weight: 500;
  font-size: 21px;
  line-height: 27px;
}
.myModal_custom-invite-top_profile-form-btn1 {
  width: 180px !important;
  border: 1px solid #DADADA !important;
  color: #2E2E2E !important;
  font-size: 0.9em;
  background-color: white !important;
  margin: 5px 0 0 !important;
}
.myModal_custom-invite-bottom {
  padding: 1em 0;
  display: flex;
  width: 300px;
}
.myModal_custom-invite-bottom .cancel {
  border: 1px solid #D0D0D0;
  padding: 2px;
  color: #333333;
  background: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.5px;
  width: 112px;
}
.myModal_custom-invite-bottom .publish {
  background: #0046E6;
  border: 1px solid #0046E6;
  margin-left: auto;
  padding: 10px;
  border-radius: 7px;
  color: white;
  width: 112px;
}
.myModal_custom_permission {
  width: 400px;
  padding: 10px 20px 20px 20px;
}
.myModal_custom_permission p span {
  background-color: aliceblue;
  padding: 4px 12px;
  border-radius: 10px;
  float: right;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-top: -8px;
  color: #0046E6;
  cursor: pointer;
}
@media (max-width: 560px) {
  .myModal_custom_permission {
    width: 100%;
  }
}
.myModal_custom_permission label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
}
.myModal_newshare {
  width: 400px;
  border-radius: 10px;
  z-index: 3;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 560px) {
  .myModal_newshare {
    width: 300px;
  }
}
.myModal_newshare-content {
  background-color: white;
}
.myModal_newshare-content-flex {
  display: flex;
  justify-content: space-around;
}
.myModal_newshare-content-flex p {
  width: 50%;
  text-align: center;
  font-size: 1.3em;
  font-weight: 600;
  margin: 0;
  padding: 10px 0;
  cursor: pointer;
  border-radius: 20px 20px 0 0;
}
.myModal_newshare-content-flex .notactive {
  background-color: black;
  color: white;
}
.myModal_newshare-content-flex .active {
  background-color: white;
}
.myModal_newshare-content-wa {
  padding: 30px 30px 40px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 0 0 10px 10px;
}
.myModal_newshare-content-wa .mail {
  padding: 7px 15px;
}
.myModal_newshare-content-wa label {
  margin: 0 0 10px;
}
.myModal_newshare-content-wa input {
  width: 100%;
  border: 1px solid #DADADA;
  border-radius: 4px;
}
.myModal_team {
  width: 612px;
  border-radius: 14px;
  z-index: 3;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 560px) {
  .myModal_team {
    width: 300px;
  }
}
.myModal_team-content {
  background-color: white;
  box-sizing: border-box;
  background-color: white;
  border-radius: 0 0 10px 10px;
  padding: 30px 0;
}
.myModal_team-content-header {
  padding: 0 30px 30px;
}
.myModal_team-content-header h4 {
  font-weight: 700;
  font-size: 27.9644px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 1em;
}
.myModal_team-content-input {
  width: 100%;
  border: 1px solid #DADADA;
  border-radius: 4px;
  padding: 5px;
}
.myModal_team-content-div {
  width: 100%;
}
.myModal_team-content-div-container {
  padding: 20px 30px;
  border-top: 1px solid #D2D2D2;
  border-bottom: 1px solid #D2D2D2;
}
.myModal_team-content-div-container p {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
}
.myModal_team-content-div-container input {
  margin-right: 10px;
}
.myModal_team-content-wa {
  padding: 30px 30px 40px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 0 0 10px 10px;
}
.myModal_team-content-wa .mail {
  padding: 7px 15px;
}
.myModal_team-content-wa label {
  margin: 0 0 10px;
}

.table {
  box-sizing: border-box;
  box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  width: 100%;
  padding: 5px 0px;
  margin-top: 20px;
}
@media (max-width: 1400px) {
  .table {
    overflow-x: scroll;
  }
}
.table table {
  width: 100%;
}
@media (max-width: 1400px) {
  .table table {
    min-width: 1400px;
  }
}
.table table tbody {
  border: none !important;
}
.table table tbody tr {
  /* Light Version/Neutral */
  border-radius: 10px;
  box-shadow: -1px 4px 17px -11px rgba(0, 0, 0, 0.25);
  border-radius: 8.91046px;
  margin-bottom: 15px;
  padding: 10px !important;
  height: 70px;
}
.table table thead {
  border: 1px solid #D8D8D8;
  background: #F0F0F0;
  height: 48px;
  border-radius: 4.5px;
  padding: 20px 10px;
}
.table table thead tr {
  border-bottom: 0;
  font-size: 1em;
}
@media (max-width: 560px) {
  .table table thead tr {
    font-size: 0.9em;
  }
}
.table table thead th {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}
.table table th, .table table td {
  padding: 8px;
}
.table table td {
  font-weight: 400;
  font-size: 14.483px;
  line-height: 19px;
  color: #06152B;
}
.table table th {
  opacity: 0.56;
  font-weight: 400;
  text-transform: capitalize !important;
  font-weight: 400;
  font-size: 12.8738px;
  line-height: 17px;
  /* identical to box height */
  /* Light Version/Text */
  color: #06152B;
}
.table-bluebox {
  width: 100px;
  height: 30.99px;
  background: #DCE7FF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8.26269px;
  color: #3A36DB;
}
.table-pendingBox {
  width: 63px;
  width: 136.5px;
  background: #ffebf4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 19.2361px;
  color: #FF69B4;
  padding: 5px 10px;
}
.table-approvedBox {
  width: 136.5px;
  height: 30.99px;
  background: #dcfffd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 19.2361px;
  color: #03A89E;
  padding: 5px 10px;
}
.table-requestBox {
  padding: 6px 11px;
  height: 32px;
  background: #0046E6;
  border-radius: 6px;
  font-size: 15.22px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
}
.table-walletCredit {
  color: #03A89E !important;
}
.table-walletDebit {
  color: #FF69B4 !important;
}
.table_income-btn button {
  font-weight: 500;
  font-size: 15.22px;
  line-height: 20px;
  padding: 6px 11px;
  color: white;
  border: 1px solid transparent;
  border-radius: 6px;
  width: 83px;
}
.table_income-btn button img {
  width: 20px;
}
.table_income-btn .view {
  background: #47D800;
  margin-right: 10px;
}
.table_income-btn .see {
  background: #0046E6;
  margin-right: 10px;
  width: 39px;
  height: 32px;
  padding: 0 !important;
}
.table_income-btn .see img {
  margin-bottom: 2px;
}
.table_income-btn .reload {
  background: #FB2C2C;
}
.table_income-btn .share {
  background-color: #0046E6;
}
.table_income-btn .share img {
  margin: -3px 5px 0 0;
  width: 15px;
}
.table_income-btn .link {
  background-color: #47D800;
  margin-right: 10px;
}
.table_income-btn .link img {
  margin-bottom: 3px;
  margin-right: 8px;
}
.table_audit .green {
  color: #47D800;
}
.table_audit .red {
  color: #FF2608;
}
.table_audit-btn button {
  font-size: 0.9em;
  color: white;
  padding: 4px 10px;
  border-radius: 5px;
  text-transform: capitalize;
}
.table_audit-btn .success {
  background: #47D800;
  border: 1px solid #47D800;
}
.table_audit-btn .failed {
  background: #FF2608;
  border: 1px solid #FF2608;
}
.table_page {
  display: flex;
  justify-content: center;
  margin: 15px auto 0;
}
.table_page .active {
  background: #DCE7FF;
  color: #0046E6 !important;
}
.table_page p {
  padding: 5px 10px;
  margin: 0;
}
.table_page-num {
  color: #667085;
  width: 38px;
  flex-shrink: 0;
  margin: 0 2px;
  cursor: pointer;
  text-align: center;
  border-radius: 7px;
}
.table_view {
  text-align: center;
  margin-top: 20px;
}
.table_view button {
  width: 100px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.accounts {
  box-sizing: border-box;
}
.accounts_name {
  margin-bottom: 50px;
  display: flex;
}
.accounts_name-tag {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #CE06E0;
  position: relative;
}
.accounts_name-tag p {
  color: white;
  font-weight: 700;
  font-size: 36px;
  line-height: 47px;
  text-align: center;
  margin-top: 3px;
  text-transform: uppercase;
}
.accounts_name h3 {
  margin: 7px 0 0 15px;
  text-transform: capitalize;
}
.accounts_details {
  border: 0.25px solid #0046E6;
  box-sizing: border-box;
  box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  width: 589px;
  padding: 36px;
  margin-top: 50px;
}
@media (max-width: 560px) {
  .accounts_details {
    width: 100%;
  }
}
.accounts_details-top p {
  margin-bottom: 0 !important;
}
.accounts_details-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 20px 0;
}
.accounts_details-main {
  color: #6C6C6C;
  font-size: 1em;
  flex: 1;
  margin-right: 30px;
}
.accounts_details-main .single {
  display: flex;
}
.accounts_details-main .single .value {
  margin-left: auto;
}
.accounts_details-main .text1 {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
}
.accounts_details-main .text2 {
  color: black !important;
  font-weight: 500;
  font-size: 21px;
  line-height: 27px;
}
.accounts_permission {
  border: 0.25px solid #0046E6;
  box-sizing: border-box;
  box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  width: 300px;
  padding: 20px;
  margin-top: 20px;
}
.accounts_permission p span {
  background-color: aliceblue;
  padding: 4px 12px;
  border-radius: 10px;
  float: right;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-top: -8px;
  color: #0046E6;
  cursor: pointer;
}
@media (max-width: 560px) {
  .accounts_permission {
    width: 100%;
  }
}
.accounts_permission label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
}
.accounts_permission button {
  margin-top: 10px;
  width: 100px;
  height: 40px;
  color: #FFFFFF;
  background: #0046E6;
  border-radius: 8px;
  border: 0;
  font-weight: 500;
}
.accounts_topHeader {
  height: 182px;
  width: 100%;
  background: #EEF3FF;
  border-radius: 18px;
  margin: 2em 0;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accounts_topHeader-image {
  width: 160px;
  height: 160px;
  border: 4px solid #FFFFFF;
  border-radius: 50%;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  margin-right: 50px;
}
.accounts_topHeader-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.accounts_topHeader-content h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
}
.accounts_topHeader-content p {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0.2rem;
}
.accounts_topHeader-content .isLive {
  width: 65px;
  height: 20.99px;
  background: #47D800;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #fff;
  padding: 5px;
  margin-left: 15px;
}
.accounts_topHeader-content .isNotLive {
  width: 65px;
  height: 20.99px;
  background: #FB2C2C;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #fff;
  padding: 5px 10px;
  margin-left: 15px;
}
.accounts_otherDetails {
  display: flex;
  align-items: center;
  margin: 30px 0 3em 0;
}
.accounts_otherDetails-content {
  margin-right: 30px;
}
.accounts_otherDetails-content .text1 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin: 0 !important;
}
.accounts_otherDetails-content .text2 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #344054;
  margin: 0 !important;
}

.income_actions {
  margin: 40px;
}
@media (min-width: 769px) {
  .income_actions {
    display: flex;
  }
}
.income_actions-box {
  border: 0.25px solid #0046E6;
  box-sizing: border-box;
  box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  width: 300px;
  padding: 15px;
  text-align: center;
  margin: 0 40px 0 0;
}
@media (max-width: 769px) {
  .income_actions-box {
    width: 100%;
    margin-bottom: 30px;
  }
}
.income_actions-radar {
  border: 0.25px solid #0046E6;
  box-sizing: border-box;
  box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  width: 300px;
  padding: 15px;
  margin: 0 40px 0 0;
}
@media (max-width: 769px) {
  .income_actions-radar {
    width: 100%;
    margin-bottom: 30px;
  }
}
.income_actions-radar .comingSoon {
  display: flex;
}
.income_actions-radar .comingSoon p {
  color: white;
  background: #FFC702;
  font-size: 0.75em;
  border-radius: 5px;
  height: 1.4em;
  padding: 0px 2px !important;
  margin: 12px 0 0 7px;
}
.income_actions-radar input {
  width: 100%;
  margin: 10px 0 20px;
  padding: 5px 15px;
  height: 40px;
  border: 2px solid #C4C4C4;
  border-radius: 5px;
  color: #8A8A8A;
}
.income_actions-radar button {
  width: 100%;
}
.income_actions h4 {
  font-size: 1.2em;
  margin: 10px 0 !important;
}
.income_actions button {
  border: 1px solid #0046E6;
  border-radius: 8px;
  background-color: #0046E6;
  padding: 8px 15px;
  color: white;
  margin: 0 0 15px;
  text-align: center;
  font-weight: 500;
}
.income_actions-radarNumber {
  border: 0.25px solid #0046E6;
  box-sizing: border-box;
  box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  width: 300px;
  padding: 15px;
  margin: 0;
}
@media (max-width: 769px) {
  .income_actions-radarNumber {
    width: 100%;
    margin-bottom: 30px;
  }
}
.income_actions-radarNumber .comingSoon {
  display: flex;
}
.income_actions-radarNumber .comingSoon p {
  color: white;
  background: #FFC702;
  font-size: 0.75em;
  border-radius: 5px;
  height: 1.4em;
  padding: 0px 2px !important;
  margin: 12px 0 0 7px;
}
.income_actions-radarNumber input {
  width: 100%;
  margin: 10px 0 20px;
  padding: 5px 15px;
  height: 40px;
  border: 2px solid #C4C4C4;
  border-radius: 5px;
  color: #8A8A8A;
}
.income_actions-radarNumber button {
  width: 100%;
}
.income_cards-front {
  width: 400px;
  height: 220px;
  color: white;
  border-radius: 15px;
  padding: 20px;
}
.income_cards-front-logo {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
}
.income_cards-front-logo img {
  width: 100%;
}
.income_cards-front-text {
  margin: 0px 10px;
}
.income_cards-front-text p {
  margin: 0 !important;
  font-size: 0.9em;
}
.income_cards-front-text h4 {
  margin: 0 !important;
  font-size: 1.3em;
}

.apps_main {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 560px) {
  .apps_main {
    margin: 0px 30px 0 0;
  }
}
.apps_main a {
  text-decoration: none;
  color: #0F123F;
}
.apps_main__button {
  position: absolute;
}
.apps_main-box {
  border: 0.25px solid #0046E6;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 15px;
  margin: 15px;
  display: flex;
  align-items: center;
  width: 250px;
  height: 150px;
}
.apps_main-box button {
  position: relative;
  padding: 2px;
  border-radius: 5px;
  width: 40px;
  opacity: 0.7;
  margin-top: 30px;
  /* z-index: 10; */
  /* display:none */
}
.apps_main-box-picture {
  width: 45px;
  height: 45px;
  overflow: hidden;
  border-radius: 50%;
}
.apps_main-box-picture img {
  width: 100%;
  height: auto;
}
.apps_main-box-text {
  margin: 0 0 0 15px;
  font-size: 20px;
}
.apps_main-box-text p {
  margin: 0;
}
.apps_main-box-text span {
  color: #00B412;
  font-size: 0.8em;
  cursor: pointer;
}
.apps_main-box1 {
  border: 0.25px solid #0046E6;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 30px 30px 20px 30px;
  margin: 15px;
  display: flex;
}
@media (max-width: 769px) {
  .apps_main-box1 {
    width: 100%;
  }
}
.apps_main-box1-picture {
  width: 45px;
  height: 45px;
  overflow: hidden;
  border-radius: 50%;
  background: linear-gradient(128.58deg, #0FCD16 14.67%, #2BBD35 86.8%);
}
.apps_main-box1-picture img {
  width: 15px;
  margin: 15px 0 0 15px;
}
.apps_main-box1-text {
  font-size: 1em;
  margin: 10px 0 0 10px;
}
.apps_create {
  border: 1px solid #0046E6;
  box-sizing: border-box;
  border-radius: 15px;
  box-sizing: border-box;
  width: 625px;
  padding: 40px 25px;
  margin: 60px auto;
}
.apps_create h4 {
  text-align: center;
  font-weight: 700;
  font-size: 25px;
  line-height: 33px;
}
.apps_create p {
  font-weight: 500;
  font-size: 21px;
  line-height: 27px;
}
.apps_create-header {
  display: flex;
  justify-content: space-between;
  margin: 2.5em 0;
}
.apps_create-header .flex {
  display: flex;
}
.apps_create-header .flex a {
  text-decoration: none;
}
.apps_create-header .flex-roles {
  border: 0.3px solid #000000;
  border-radius: 7.76472px;
  width: 129.5px;
  height: 31.06px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.apps_create-header .flex-roles p {
  font-weight: 500;
  font-size: 12.4235px;
  line-height: 16px;
  margin: 0;
  color: #998F8F;
  margin-left: 8px;
}
.apps_create-header .flex-invite {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 129.5px;
  height: 31.06px;
  background: #3A36DB;
  border-radius: 7.76472px;
  margin-left: 10px;
  cursor: pointer;
}
.apps_create-header .flex-invite p {
  font-weight: 500;
  font-size: 12.4235px;
  line-height: 16px;
  margin: 0;
  color: #ffffff;
  margin-left: 8px;
}
@media (max-width: 560px) {
  .apps_create {
    width: 90%;
    margin: 30px 60px 30px 0px;
  }
}
.apps_create-input {
  width: 100%;
  height: 48px;
  border: 1px solid #DADADA;
  color: #8A8A8A;
  padding: 5px 15px;
  margin: 10px 0;
  border-radius: 7px;
}
.apps_create-button {
  padding: 5px 20px;
  border-radius: 7px;
  width: 100%;
  margin: 20px 0;
  color: white;
  background: #0046E6;
  border: 1px solid #0046E6;
}
.apps_keys {
  margin: 0px 70px 30px 40px;
  color: #0F123F;
  box-sizing: border-box;
}
@media (max-width: 560px) {
  .apps_keys {
    margin: 30px 20px 30px 0px;
  }
}
.apps_keys-topText {
  margin: 10px 0;
}
.apps_keys-topText p {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
.apps_keys-box {
  border: 1px solid #0046E6;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  margin: 0 0 30px;
}
.apps_keys-box p {
  margin-bottom: 0 !important;
}
.apps_keys-box img {
  width: 40px;
  margin-right: 25px;
}
.apps_keys-box1 {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 15px;
  margin: 0 0 30px;
}
.apps_keys-box1 p {
  margin-bottom: 0 !important;
}
.apps_keys-box2 {
  border: 1px solid #0046E6;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 15px;
}
.apps_keys-box2 hr {
  margin: 0 0 10px !important;
}
@media (max-width: 1200px) {
  .apps_keys-box2 {
    margin-bottom: 30px;
  }
}
.apps_keys-box2-title {
  display: flex;
  margin-bottom: 20px;
}
.apps_keys-box2-title h5 {
  font-weight: 700;
  margin-top: 4px;
  font-size: 1.3em;
}
.apps_keys-box2-title button {
  border: 1px solid #0046E6;
  border-radius: 5px;
  font-size: 0.8em;
  padding: 3px 9px;
  background: white;
  margin-left: auto;
}
.apps_keys-box2-title button img {
  width: 12px;
  margin: -1px 5px 0 0;
}
.apps_keys-box2-data {
  display: flex;
}
.apps_keys-box2-data p {
  width: 100px;
  margin-top: 3px;
  flex-shrink: 0;
}
.apps_keys-box2-data-key {
  position: relative;
  width: 100%;
}
.apps_keys-box2-data-key input {
  border: 2px solid #0046E6;
  border-radius: 4px;
  padding: 1px 10px;
  font-size: 11px;
  height: 30px;
  width: 100%;
  background: #ECECEC;
}
.apps_keys-box2-data-key-cover {
  border: 2px solid #0046E6;
  border-radius: 0 4px 4px 0;
  background: #ECECEC;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 15px;
}
.apps_keys-box2-data-key img {
  position: absolute;
  top: 7px;
  right: 7px;
  width: 18px;
}
.apps_keys-box2-data-copy {
  margin: -13px 0 0 15px;
}

.settings_navMain {
  border-bottom: 1px solid #B5B5B5;
  display: flex;
  padding: 0 30px;
}
@media (max-width: 769px) {
  .settings_navMain {
    padding: 0 10px;
  }
}
.settings_navMain p {
  margin: 0 20px;
  padding: 20px 10px 10px;
  cursor: pointer;
  color: black;
}
@media (max-width: 769px) {
  .settings_navMain p {
    padding: 10px 5px 7px;
    font-size: 0.9em;
    margin: 0 10px;
  }
}
.settings_navMain .active {
  border-bottom: 3px solid #0046E6;
}
.settings_subNav {
  border-bottom: 1px solid #B5B5B5;
  display: flex;
  padding: 10px 30px 0 10px;
  margin-left: 30px;
}
@media (max-width: 769px) {
  .settings_subNav {
    margin-left: 0px;
  }
}
.settings_subNav p {
  margin: 0 20px;
  color: black;
  padding: 20px 10px 10px;
  cursor: pointer;
}
@media (max-width: 769px) {
  .settings_subNav p {
    padding: 10px 5px 7px;
    font-size: 0.9em;
    margin: 0 10px;
  }
}
.settings_subNav .active {
  border-bottom: 3px solid #0046E6;
}
.settings_members {
  box-sizing: border-box;
  margin: 100px 60px;
}
.settings_members-table {
  border: 0.25px solid #0046E6;
  box-sizing: border-box;
  box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  width: 90%;
  padding: 15px 0px;
  margin: 30px 0;
}
@media (max-width: 992px) {
  .settings_members-table {
    width: 100%;
    overflow-x: scroll;
    margin: 50px 0px;
    max-width: 90% !important;
  }
}
@media (max-width: 992px) {
  .settings_members-table-data {
    min-width: 950px;
  }
}
.settings_members-table-top {
  display: flex;
  margin: 0 25px;
}
.settings_members-table-top h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height */
  letter-spacing: 0.02em;
  color: rgba(28, 28, 28, 0.85);
}
.settings_members-table-top-btn {
  margin-left: auto;
  display: flex;
}
.settings_members-table-top-btn-form {
  width: 270px;
  position: relative;
  padding: 0 !important;
  margin: 0 15px 0 0;
}
.settings_members-table-top-btn-form input {
  width: 100%;
  height: 30px;
  background: #F5F5F5;
  border: 1px solid #DADADA;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 10px 5px 30px;
  font-size: 0.9em;
  color: #797979;
}
.settings_members-table-top-btn-form img {
  position: absolute;
  top: 8px;
  left: 6px;
  width: 15px;
}
.settings_members-table-top-btn button {
  height: 30px;
  border: 1px solid #0046E6;
  color: white;
  background: #0046E6;
  width: 200px;
  padding: 0px 15px;
  font-size: 0.9em;
  border-radius: 6px;
}
.settings_members-table-main {
  padding: 0 20px;
}
.settings_members-table-main-action button {
  border: 1px solid #DADADA;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
  background-color: white;
  color: #797979;
}
.settings_members-table-main-status {
  color: #087F00;
}
.settings_members-table table {
  width: 100%;
}
.settings_members-table table tr {
  border-bottom: 0.75px solid #B5B5B5;
  color: #0F123F;
  font-size: 1em;
}
.settings_members-table table th, .settings_members-table table td {
  padding: 10px 0;
}
.settings_members-table table th {
  opacity: 0.56;
  font-weight: 400;
  text-transform: capitalize !important;
}
.settings_roles {
  margin: 0 30px;
}
.settings_roles-default {
  font-weight: 700;
}
.settings_roles-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
}
.settings_roles-item-icon {
  width: 50px;
  height: 50px;
  overflow: hidden;
  margin-right: 10px;
  border-radius: 50%;
}
.settings_roles-item-icon img {
  width: 20px;
  margin: 14px 0 0 14px;
}
.settings_roles-item-text {
  width: 80%;
}
.settings_roles-item-text .title {
  color: #0F123F;
  margin: 0;
}
.settings_roles-item-text .desc {
  color: #797979;
  width: 100%;
  margin: 0 0 5px 0;
}
.settings_roles-item button {
  margin-left: auto;
  border: 1px solid #DADADA;
  box-sizing: border-box;
  border-radius: 4px;
  background: white;
  height: 35px;
  width: 158px;
  font-size: 0.9em;
  color: #797979;
  padding: 4px;
}
.settings_roles .cards {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 4em 0;
}
.settings_roles .cards-green {
  width: 365.5px;
  height: 532px;
  background: #FAFAFA;
  border: 0.4px solid #0046E6;
  border-radius: 11px;
}
.settings_roles .cards-green-top {
  background: rgba(43, 189, 53, 0.19);
  border: 0.4px solid #0046E6;
  border-radius: 11px 11px 0px 0px;
  height: 72.57px;
  display: flex;
  align-items: center;
}
.settings_roles .cards-green-top p {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #2BBD35;
  margin: 0 0 0 20px;
}
.settings_roles .cards-red {
  width: 365.5px;
  height: 532px;
  left: 431.5px;
  top: 513.5px;
  background: #FAFAFA;
  border: 0.4px solid #0046E6;
  border-radius: 11px;
}
.settings_roles .cards-red-top {
  height: 72.57px;
  left: 837.5px;
  top: 513.5px;
  background: rgba(255, 0, 0, 0.15);
  border: 0.4px solid #0046E6;
  border-radius: 11px 11px 0px 0px;
  display: flex;
  align-items: center;
}
.settings_roles .cards-red-top p {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: rgba(255, 0, 31, 0.85);
  margin: 0 0 0 20px;
}
.settings_roles .custom {
  color: #0046E6;
  cursor: pointer;
  font-weight: 700;
}
.settings_profile-form {
  padding: 2em 5em;
}
.settings_profile-form-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.settings_profile-form-div .input {
  width: 326px;
  height: 48px;
  border: 1px solid #DADADA;
  color: #8A8A8A;
  padding: 5px 10px;
  margin: 10px 0;
  border-radius: 7px;
  display: flex;
  align-items: center;
}
.settings_profile-form-div .input input {
  border: none;
  margin-left: 20px;
  width: 100%;
}
.settings_profile-form label {
  font-weight: 500;
  font-size: 21px;
  line-height: 27px;
}
.settings_profile-form-btn1 {
  width: 180px !important;
  border: 1px solid #DADADA !important;
  color: #2E2E2E !important;
  font-size: 0.9em;
  background-color: white !important;
  margin: 5px 0 0 !important;
}

.auditLogs_table {
  margin: 0 40px;
}

.apis {
  margin: 50px 0px 0 20px;
}
@media (max-width: 1350px) {
  .apis {
    margin: 50px 0px 0;
  }
}
@media (max-width: 560px) {
  .apis {
    margin: 0;
  }
}
.apis_row1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-content: flex-start;
}
@media (max-width: 1350px) {
  .apis_row1 .income1 {
    display: none;
  }
}
@media (max-width: 650px) {
  .apis_row1 .income1 {
    margin-top: 70px;
    display: initial;
  }
}
@media (min-width: 1350px) {
  .apis_row1 .income2 {
    display: none;
  }
}
@media (max-width: 650px) {
  .apis_row1 .income2 {
    display: none;
  }
}
.apis_box {
  border: 0.5px solid #0046E6;
  box-sizing: border-box;
  box-shadow: 0.453849px 0.902061px 26.1391px 0.902061px rgba(0, 0, 0, 0.1);
  border-radius: 22px;
  padding: 15px;
  margin: 20px;
  min-height: 600px;
}
.apis_box-1 {
  width: 20%;
}
@media (max-width: 1100px) {
  .apis_box-1 {
    width: 43%;
  }
}
@media (max-width: 650px) {
  .apis_box-1 {
    width: 100%;
  }
}
.apis_box-2 {
  margin: 0;
  box-sizing: border-box;
  display: flex;
}
@media (max-width: 1100px) {
  .apis_box-2 {
    flex-wrap: wrap;
  }
}
.apis_box-title {
  text-align: center;
}
.apis_box-3 {
  width: 20%;
}
@media (max-width: 1100px) {
  .apis_box-3 {
    width: 43%;
  }
}
@media (max-width: 650px) {
  .apis_box-3 {
    width: 100%;
  }
}
.apis_box-3 h6 {
  color: #0046E6;
  font-size: 0.78em;
}
.apis_box-3-topText {
  margin-bottom: 10px !important;
  font-size: 0.8em;
  color: #0046E6;
}
.apis_box-3-topText span {
  color: #B5AFAF;
  font-weight: 700;
  font-size: 0.68em;
}
.apis_box-3-list {
  display: flex;
  align-items: flex-start;
  font-size: 0.8em;
}
.apis_box-3-list img {
  width: 15px !important;
  flex-shrink: 0;
  margin: 5px 10px 0 0;
}
.apis_box-4 {
  width: 70%;
}
@media (max-width: 1350px) {
  .apis_box-4 {
    width: 100%;
  }
}
.apis_box h5 {
  color: #0046E6;
  font-size: 1.4em;
}
.apis_box-topText {
  margin-bottom: 10px !important;
  font-size: 0.9em;
  color: #0046E6;
}
.apis_box-topText span {
  color: #B5AFAF;
  font-weight: 700;
  font-size: 1em;
}
.apis_box-list {
  display: flex;
  align-items: flex-start;
  font-size: 0.7em;
  color: #B5AFAF;
}
.apis_box-list img {
  width: 17px !important;
  flex-shrink: 0;
  margin: 0px 10px 0 0;
}
.apis_box-list1 {
  color: #B5AFAF;
  font-size: 0.6rem;
}
.apis_box-list1 p {
  margin: 0 !important;
}

.credit {
  margin: 0 30px 50px;
}
.credit-bvn {
  margin: 0 0 50px;
  position: relative;
}
.credit-bvn-label {
  color: #8A8A8A;
  margin: 50px 0 10px;
}
.credit-bvn-input {
  width: 300px;
  height: 40px;
  border: 1px solid #DADADA;
  border-radius: 4px;
  margin-right: 10px;
  padding: 3px 15px;
  color: #8A8A8A;
}
.credit-bvn-input-show {
  bottom: 12px;
  left: 300px;
  position: absolute;
  right: 20px;
  width: 20px;
  cursor: pointer;
}
.credit-bvn button {
  background: #0046E6;
  border: 1px solid #0046E6;
  border-radius: 5px;
  height: 40px;
  width: 90px;
  text-align: center;
  color: white;
}
.credit_classic {
  margin: 50px 0 0;
}
.credit_fico {
  background: #FFFFFF;
  border: 0.15px solid #4C8BF5;
  box-sizing: border-box;
  box-shadow: 0px 4px 90px rgba(163, 171, 185, 0.24);
  border-radius: 15px;
  margin: 0 0 30px;
}
.credit_fico hr {
  border: 0.5px solid #0046E6;
}
.credit_fico-single {
  padding: 15px;
}
.credit_fico-single-img {
  background: #0046E6;
  width: 50px;
  height: 50px;
  margin: 0 0 10px;
  border-radius: 50%;
}
.credit_fico-single-img img {
  width: 35px;
  margin: 7px 0 0 8px;
}
.credit_fico-single-top img {
  width: 30px;
  margin: -15px 10px 0 0;
}
.credit_fico-single-top p {
  font-size: 1em;
  font-weight: 700;
  opacity: 0.5;
  margin: 0 !important;
}
.credit_fico-single-text {
  font-size: 1.8em;
  font-weight: 700;
  margin: 0 !important;
}
.credit_fico-single-flex {
  display: flex;
}
.credit_fico-single-flex .average {
  color: #FF8B03;
}
.credit_fico-single-flex .good {
  color: #47D800;
}
.credit_fico-single-flex .bad {
  color: #FF2608;
}
.credit_fico-single-flex .more {
  margin: 7px 0 0 auto;
  font-weight: 700;
  font-size: 1em;
  opacity: 0.5;
  cursor: pointer;
}
.credit_fico-single-flex .more img {
  margin: -15px 0 0 10px;
}
.credit_fico-single-reasons {
  font-size: 1em;
  opacity: 0.5;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease-out;
}
.credit_fico-wrapper {
  border-radius: 10px;
  margin: 15px 0;
  padding: 0 !important;
  border: 2px solid #0046E6;
}
.credit_fico-chart-text {
  display: flex;
  align-items: center;
}
.credit_fico-chart-text p {
  margin: 0 0 0 10px;
  font-weight: 700;
  font-size: 1.3em;
  color: #4C8BF5;
}
.credit_fico-chart-main {
  position: relative;
  margin-top: 50px;
  overflow: hidden;
}
.credit_fico-chart-main .chart {
  width: 90%;
  display: block;
  margin: auto;
}
.credit_fico-chart-main .guage {
  border-width: 0px;
  height: 4px;
  width: 60%;
  left: 20%;
  background-color: black;
  position: absolute;
  background-color: #000000;
  background-image: linear-gradient(270deg, #ffffff 50%, #000000 50%);
  bottom: 5px;
  border-radius: 15px;
}
.credit_boxes {
  box-sizing: border-box;
  margin: 30px 20px 30px 0;
}
.credit_boxes-title {
  margin: 0 0 40px;
}
@media (min-width: 1200px) {
  .credit_boxes-title {
    display: flex;
  }
}
.credit_boxes-title span {
  font-size: 15px;
  margin-left: 10px;
  font-weight: 400;
}
.credit_boxes-title-buttons {
  margin-left: auto;
}
.credit_boxes-title-buttons button {
  border: 1px solid #0046E6;
  box-sizing: border-box;
  border-radius: 7px;
  padding: 5px 15px;
  font-size: 1em;
  background: #FFFFFF;
}
.credit_boxes-title-buttons button img {
  width: 20px;
  margin: -3px 4px 0 0;
}
.credit_boxes-title-buttons .share {
  margin: 0 10px 0 0;
}
.credit_boxes-single {
  background: #FFFFFF;
  border: 0.15px solid #4C8BF5;
  box-sizing: border-box;
  box-shadow: 0px 4px 90px rgba(163, 171, 185, 0.24);
  border-radius: 15px;
  padding: 15px;
  margin: 0 0 30px;
}
.credit_boxes-single-top {
  display: flex;
}
.credit_boxes-single-top img {
  width: 30px;
  margin: -15px 10px 0 0;
}
.credit_boxes-single-top p {
  font-size: 1em;
  font-weight: 700;
  opacity: 0.5;
}
.credit_boxes-single-text {
  font-size: 1.3em;
  margin: 0 !important;
}
.credit_checkTable {
  width: 100%;
}
@media (max-width: 992px) {
  .credit_checkTable {
    overflow-x: scroll;
  }
}
.credit_checkTable h5 {
  background: #D0D0D0;
  border: 1px solid #D0D0D0;
  margin: 0 0 20px 0;
}
.credit_checkTable p {
  font-size: 20px;
  font-weight: 500;
}
.credit_checkTable table {
  width: 100%;
  margin-bottom: 30px;
}
.credit_checkTable table th, .credit_checkTable table td {
  border-right: 0.5px solid #DADADA;
  padding: 0 5px !important;
}
@media (max-width: 992px) {
  .credit_checkTable table {
    width: 800px;
  }
}
.credit_checkTable table tr {
  border-bottom: 1px solid #DADADA;
}
.credit_checkTable table th, .credit_checkTable table td {
  padding: 10px 5px !important;
}
.credit_checkTable table .open {
  font-size: 0.9em;
  border: 1px solid #DB8C28;
  color: #DB8C28;
  font-weight: 600;
  border-radius: 5px;
  background: transparent;
  text-align: center;
  padding: 2px 10px;
}
.credit_checkTable table .closed {
  font-size: 0.9em;
  border: 1px solid #7FC008;
  color: #7FC008;
  font-weight: 600;
  border-radius: 5px;
  background: transparent;
  text-align: center;
  padding: 2px 10px;
}
.credit_checkTable .view {
  border: 1px solid #47D800;
  background-color: #47D800;
  border-radius: 4px;
  margin-right: 10px;
  color: white;
  width: 100px;
}
.credit_enquiry-topText {
  font-size: 1.2em;
}
.credit_enquiry-data {
  border: 0.25px solid #0046E6;
  box-sizing: border-box;
  box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
  border-radius: 10px !important;
  max-width: 600px;
  margin: 0 0 40px;
  padding: 0 20px;
}
.credit_enquiry-data table {
  width: 100%;
}
.credit_enquiry-data tr {
  border-bottom: 0.75px solid #B5B5B5;
  color: #0F123F;
  font-size: 1em;
}
@media (max-width: 560px) {
  .credit_enquiry-data tr {
    font-size: 0.9em;
  }
}
.credit_enquiry-data th, .credit_enquiry-data td {
  padding: 10px 0;
}
.credit_enquiry-data th {
  opacity: 0.56;
  font-weight: 400;
  text-transform: capitalize !important;
}
.credit_enquiry-data .view {
  border: 1px solid #47D800;
  background-color: #47D800;
  border-radius: 4px;
  margin-right: 10px;
  color: white;
  width: 100px;
}
.credit_enquiry-data .view img {
  margin-bottom: 3px;
  margin-right: 8px;
}

.select {
  position: relative;
  width: 70%;
}
.select_box {
  width: 100%;
  padding: 7px 10px;
  font-size: 15px;
  border: 1px solid #0046E6;
  border-radius: 5px;
  color: #8A8A8A;
  margin: 10px 0;
}
.select_box:active {
  border: 2px solid #296AFC;
}
.select_options {
  width: 100%;
  max-height: 500px;
  overflow-y: scroll;
  position: absolute;
  top: 50px;
  border-radius: 5px;
  left: 0;
  right: 0;
  padding: 10px 10px;
  background-color: white;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.09);
  z-index: 2;
}
.select_options p {
  padding: 5px 5px;
  border-radius: 4px;
  margin: 0 !important;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #303030;
}
.select_options p:hover {
  background: #e8e7e7;
  cursor: pointer;
}
.select_options_selected {
  background: rgba(43, 78, 158, 0.09);
}
.select_arrow {
  position: absolute;
  top: 45%;
  right: -15px;
  pointer-events: none;
}
.select_arrow-type {
  position: absolute;
  top: 28px;
  right: 15px;
  pointer-events: none;
}

.selected {
  position: relative;
  width: 70%;
}
.selected_box {
  width: 100%;
  height: 48px;
  padding: 10px 30px;
  font-size: 15px;
  border: 1px solid #DADADA;
  border-radius: 5px;
  color: #8A8A8A;
  margin: 10px 0;
}
.selected_box:active {
  border: 2px solid #296AFC;
}
.selected_options {
  width: 100%;
  max-height: 500px;
  overflow-y: scroll;
  position: absolute;
  top: 50px;
  border-radius: 5px;
  left: 0;
  right: 0;
  padding: 10px 10px;
  background-color: white;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.09);
  z-index: 2;
}
.selected_options p {
  padding: 5px 5px;
  border-radius: 4px;
  margin: 0 !important;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #303030;
}
.selected_options p:hover {
  background: #e8e7e7;
  cursor: pointer;
}
.selected_options_selected {
  background: rgba(43, 78, 158, 0.09);
}
.selected_arrow {
  position: absolute;
  top: 45%;
  right: 12px;
  pointer-events: none;
}
.selected_arrow-type {
  position: absolute;
  top: 28px;
  right: 15px;
  pointer-events: none;
}

.identity_empty {
  max-width: 600px;
  margin: 40px auto 30px;
  display: block;
  text-align: center;
}
.identity_empty img {
  width: 250px;
}
.identity_empty h4 {
  margin: 15px 0;
}
.identity_empty p span {
  color: #296AFC;
  cursor: pointer;
}
.identity_empty button {
  background-color: #0046E6;
  font-size: 1em;
  padding: 3px 10px;
  color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  text-align: left;
}
.identity_empty button img {
  margin: -3px 5px 0 0;
  width: 15px;
}
.identity_details {
  border: 1px solid #296AFC;
  margin: 30px 0;
  border-radius: 15px 0 15px 0;
}
@media (min-width: 769px) {
  .identity_details {
    display: flex;
    max-width: 800px;
  }
}
.identity_details-section1 {
  font-weight: 600;
}
@media (min-width: 769px) {
  .identity_details-section1 {
    border-right: 1px solid #296AFC;
    width: 50%;
  }
}
.identity_details-section1 .key {
  color: #828282;
}
.identity_details-section1 .numberKey {
  color: #828282;
  margin: 0;
}
.identity_details-section1 .value {
  color: #0F123F;
  margin-left: auto;
}
@media (max-width: 585px) {
  .identity_details-section1 .value {
    flex: 1;
  }
}
.identity_details-section1 .top-Text {
  margin: 0 !important;
}
.identity_details-section1-top {
  padding: 15px;
}
@media (max-width: 585px) {
  .identity_details-section1-top .column {
    flex-direction: column;
  }
}
.identity_details-section1-top .flex {
  display: flex;
}
.identity_details-section1-top .flex p {
  text-align: right;
}
.identity_details-section1-top .flex .phoneNumber {
  margin-bottom: 10px;
}
@media (max-width: 585px) {
  .identity_details-section1-top .flex .phoneNumber {
    flex: 0.5;
  }
}
.identity_details-section1-top .flex .phoneNumber .submit {
  margin: 10px 0;
  background: #0046e6;
  color: #fff;
  padding: 1px;
  border-radius: 3px;
  height: 20px;
}
.identity_details-section1-top .flex .phoneNumber .inputBox p {
  text-align: left;
}
.identity_details-section1-top .flex button {
  background: #fff;
  border: 2px solid #0046e6;
  border-radius: 5px;
  padding: 3px;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 500;
  height: 20px;
}
.identity_result {
  box-sizing: border-box;
}
.identity_result-top {
  background: #EEF3FF;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 10px 40px;
}
@media (max-width: 769px) {
  .identity_result-top {
    padding: 10px 20px;
    align-items: flex-start;
    margin-top: 60px;
  }
}
@media (max-width: 560px) {
  .identity_result-top {
    padding: 20px 10px;
  }
}
.identity_result-top_image {
  width: 130px;
  background-color: white;
  height: 130px;
  border: 2px solid white;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}
@media (max-width: 769px) {
  .identity_result-top_image {
    width: 60px;
    flex-shrink: 0;
    height: 60px;
  }
}
.identity_result-top_image img {
  width: 100%;
  height: auto;
}
.identity_result-top_image h1 {
  margin-top: 30px;
  font-size: 3.5em;
  text-align: center;
}
@media (max-width: 769px) {
  .identity_result-top_image h1 {
    margin-top: 10px;
    font-size: 2em;
  }
}
.identity_result-top h5 {
  color: #101828;
  font-size: 1.7em;
}
@media (max-width: 560px) {
  .identity_result-top h5 {
    font-size: 1.4em;
  }
}
.identity_result-top p {
  color: #667085;
  font-weight: 700;
}
.identity_result-top_button {
  margin-left: auto;
  width: 35px;
}
@media (max-width: 560px) {
  .identity_result-top_button {
    display: none;
  }
}
.identity_result-box1 {
  padding: 5px 10px;
}
.identity_result-box1 .text1 {
  color: #667085;
  margin: 0;
}
.identity_result-box1 .text2 {
  color: #344054;
}
.identity_result-box1 .text3 {
  color: #427CFF;
  text-transform: lowercase;
}
.identity_result-box1-uploadBox {
  display: flex;
  align-items: flex-start;
  border: 1px solid #D0D5DD;
  border-radius: 4px;
  padding: 10px;
  margin-top: 15px;
}
.identity_result-box1-uploadBox h6, .identity_result-box1-uploadBox p {
  margin: 0 0 0 5px;
}
.identity_result-box1-uploadBox-txt {
  overflow: hidden;
  margin-right: 5px;
}
.identity_result-box1-uploadBox-dwnld {
  margin-left: auto;
  border-radius: 4px;
  border: 1px solid #D0D5DD;
  padding: 2px 5px 5px;
  cursor: pointer;
}
.identity_result-box1 .phoneNumber {
  margin-top: 10px;
}
.identity_result-box1 .phoneNumber button {
  background: #fff;
  border: 2px solid #0046e6;
  border-radius: 5px;
  padding: 3px;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 500;
}
.identity_result-box1 .phoneNumber .submit {
  margin: 10px 0;
  background: #0046e6;
  color: #fff;
  padding: 3px 15px;
  border-radius: 3px;
}
.identity_result-box1 .phoneNumber .inputBox {
  border: 1px solid #828282;
  padding: 5px 10px;
  margin: -10px 0 0;
  border-radius: 4px;
}
.identity_result-box1 .phoneNumber .inputBox p {
  text-align: left;
}
.identity_result-tableBox {
  overflow-x: auto;
}
.identity_result-table {
  width: 1800px;
  text-transform: capitalize;
}
.identity_result-table thead {
  background-color: #F0F0F0;
  font-size: 1em;
  border-radius: 5px;
}
.identity_result-table thead th {
  color: #667085;
  padding: 5px 7px;
  font-weight: 400;
}
.identity_result-table tbody {
  font-size: 1em;
}
.identity_result-table tbody td {
  padding: 5px 7px;
}

.credit-card {
  cursor: pointer;
  /* flip speed */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */
}
.credit-card .flip-container.hover .flipper {
  transform: rotateY(180deg);
}
.credit-card .flip-container,
.credit-card .front,
.credit-card .back {
  width: 410px;
  height: 250.5px;
  border-radius: 10px;
}
.credit-card .flipper {
  transition: 0.8s;
  transform-style: preserve-3d;
  position: relative;
}
.credit-card .front,
.credit-card .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.credit-card .front {
  z-index: 2;
  transform: rotateY(0deg);
  color: white;
}
.credit-card .front .credit-card-content {
  display: flex;
  flex-direction: column;
}
.credit-card .front .credit-card-content .top .left .left-content {
  display: flex;
}
.credit-card .front .credit-card-content .top .left .left-content .left-2 {
  margin-left: 7px;
}
.credit-card .front .credit-card-content .top .left .left-content .left-2 h3 {
  font-weight: 200;
}
.credit-card .front .credit-card-content .top .left .left-content .left-2 p {
  font-size: 13px;
}
.credit-card .front .credit-card-content .top .right .right-content {
  text-align: center;
}
.credit-card .front .credit-card-content .top .right .right-content p {
  font-size: 12px;
}
.credit-card .front .credit-card-content .top .right .right-content .linkchain-wrapper {
  border: 1px solid;
  height: 32px;
  width: 32px;
  padding: 6px;
  border-radius: 50%;
  margin-left: 26px;
  margin-bottom: 3px;
}
.credit-card .front .credit-card-content .bottom {
  margin-top: 60px;
}
.credit-card .front .credit-card-content .bottom .left h2 {
  font-weight: 200;
}
.credit-card .front .credit-card-content .bottom .right p {
  margin-top: 32px;
}
.credit-card .back {
  transform: rotateY(180deg);
  color: white;
}
.credit-card .flip-img-wrapper {
  margin-left: 190px;
  margin-top: 0px;
  z-index: 20;
  cursor: pointer;
}
.credit-card .back-money-summary {
  position: absolute;
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  right: 0;
  margin-right: 18px;
  text-align: center;
}
.credit-card .back-money-summary h3 {
  font-weight: 200;
  font-size: 17.5px;
}
.credit-card .back-money-summary p {
  font-size: 13px;
}
.credit-card .back-money-summary .money-received-wrapper {
  margin-bottom: 10px;
}

.bankAccs {
  display: flex;
  margin: 0 0 10px;
  align-items: center;
}
.bankAccs_pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.bankAccs_pic img {
  width: 100%;
}
.bankAccs p {
  font-weight: 400;
  font-size: 17px;
}
.bankAccs_name {
  margin: 0 0 0 10px;
}
.bankAccs_accNo {
  margin: 0 0 0 auto;
}

.box_business {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px !important;
}
.box_container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 20px;
}
.box_container-box {
  height: 108px;
  background: #FFFFFF;
  box-shadow: 0px 13.3657px 44.5523px rgba(160, 163, 189, 0.5);
  border-radius: 8.91046px;
  display: flex;
  align-items: center;
  padding: 15px;
}
@media (max-width: 1380px) {
  .box_container-box {
    height: 88px;
  }
}
@media (max-width: 1260px) {
  .box_container-box {
    padding: 8px;
  }
}
.box_container-box-image {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: rgb(233, 236, 254);
  display: flex;
  align-items: center;
  justify-content: center;
}
.box_container-box-details {
  padding: 20px 0 0 10px;
  width: 100%;
}
@media (max-width: 1380px) {
  .box_container-box-details {
    padding: 10px 0 0 8px;
  }
}
.box_container-box-details h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #171717;
  margin-bottom: 5px;
}
@media (max-width: 1380px) {
  .box_container-box-details h5 {
    font-size: 15px;
  }
}
.box_container-box-details p {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #6E6E6E;
  margin-bottom: 5px;
}
@media (max-width: 1380px) {
  .box_container-box-details p {
    font-size: 11px;
  }
}
.box_container-box-details p .percent {
  font-weight: 400;
  font-size: 12.4746px;
  line-height: 15px;
  color: #4BDE97;
  margin-right: 10px;
  font-size: 12.4746px;
}
@media (max-width: 1380px) {
  .box_container-box-details p .percent {
    font-size: 10px;
  }
}
.box_container-box-details p .percentRed {
  font-weight: 400;
  font-size: 12.4746px;
  line-height: 15px;
  color: #FA582E;
  margin-right: 10px;
}
@media (max-width: 1380px) {
  .box_container-box-details p .percentRed {
    font-size: 10px;
  }
}
.box_container-box-details p .small-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #B9B9B9;
}
@media (max-width: 1380px) {
  .box_container-box-details p .small-text {
    font-size: 10px;
  }
}
.box_container-thirdParty {
  width: 210px;
  height: 108px;
  background: #FFFFFF;
  box-shadow: 0px 13.3657px 44.5523px rgba(160, 163, 189, 0.5);
  border-radius: 8.91046px;
  align-items: center;
  padding: 10px;
  margin: 0 30px 30px 0;
}
.box_container-thirdParty-image {
  width: 53.67px;
  height: 53.67px;
  border-radius: 50%;
  background-color: rgb(240, 248, 255);
  display: flex;
  align-items: center;
  justify-content: center;
}
.box_container-thirdParty-details {
  padding: 10px 10px 10px 10px;
}
.box_container-thirdParty-details h6 {
  font-weight: 700;
  font-size: 14.7882px;
  line-height: 21px;
  color: #171717;
  margin-bottom: 25px;
}
.box_container-thirdParty-details h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #171717;
  margin-bottom: 5px;
}
.box_container-thirdParty-details p {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #6E6E6E;
  margin-bottom: 5px;
}
.box_container-thirdParty-details p .percent {
  font-weight: 400;
  font-size: 12.4746px;
  line-height: 15px;
  color: #4BDE97;
  margin-right: 10px;
}
.box_container-thirdParty-details p .small-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #B9B9B9;
}

.approvals {
  width: 100%;
  height: 100%;
}
.approvals_nav {
  overflow-x: scroll;
}
.approvals_nav p {
  white-space: nowrap;
}
.approvals_terms .container {
  position: relative;
  padding-left: 23px;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  max-width: 200px;
}
.approvals_terms .container p {
  margin-top: -26px;
  color: #666666;
  font-weight: 500;
}
.approvals_terms .container input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.approvals_terms .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  border: 2px solid #666666;
}
.approvals_terms .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.approvals_terms .container input:checked ~ .checkmark:after {
  display: block;
}
.approvals_terms .container .checkmark:after {
  left: 4px;
  width: 6px;
  height: 10px;
  border: solid #47D800;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.techSupport_table {
  margin: 0 40px;
}
.techSupport_details {
  width: 450px;
  border: 1px solid #E7E7E7;
  border-radius: 7px;
  padding: 15px;
  font-weight: 600;
  top: 0px;
  right: 0px;
  background: #F5F8FF;
  overflow-y: scroll;
  margin: 20px 0;
  flex: 1;
  position: relative;
}
.techSupport_details_body {
  position: relative;
  overflow-y: hidden;
}
.techSupport_details .cancel {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.techSupport_details h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}
.techSupport_details p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
}
.techSupport_details .text1 {
  color: #818181;
}
.techSupport_details .text1 span {
  color: #161616;
  margin-left: auto;
  float: right;
}
.techSupport_details .open {
  font-size: 0.9em;
  border: 1px solid rgba(196, 32, 92, 0.19);
  color: #D1373F;
  font-weight: 600;
  border-radius: 25px;
  background: rgba(196, 32, 92, 0.19);
  text-align: center;
  padding: 2px 10px;
  display: flex;
  align-items: center;
  cursor: default;
}
.techSupport_details .open div {
  background: #D1373F;
  width: 5px;
  margin-right: 4px;
  height: 5px;
  font-size: 2em;
  border-radius: 50%;
}
.techSupport_details .closed {
  font-size: 0.9em;
  border: 1px solid #ECFDF3;
  color: #027A48;
  font-weight: 600;
  border-radius: 25px;
  background: #ECFDF3;
  text-align: center;
  padding: 2px 10px;
  cursor: default;
  display: flex;
  align-items: center;
}
.techSupport_details .closed div {
  background: #027A48;
  width: 5px;
  margin-right: 4px;
  height: 5px;
  font-size: 2em;
  border-radius: 50%;
}
.techSupport_details-code h5 {
  color: #0F123F;
  font-size: 1.1em;
  margin: 50px 0 10px;
}
.techSupport_details-code pre {
  border: 1px solid #EAECF0;
  border-radius: 10px;
  padding: 10px;
}
.techSupport_flex {
  display: flex;
}
.techSupport_block {
  display: block;
}
.techSupport_sixty {
  width: 60%;
}
.techSupport_hundred {
  width: 100%;
}
.techSupport_apiService {
  padding: 2rem;
}
.techSupport_apiService .new {
  display: flex;
  width: 280px;
  height: 70px;
  background: #FFFFFF;
  border: 1px solid #0046E6;
  border-radius: 10px;
  align-items: center;
  padding: 0.2rem 1rem;
  cursor: pointer;
}
.techSupport_apiService .new h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  margin-left: 1rem;
}
.techSupport_apiService .main {
  display: flex;
  padding: 3rem 0;
  flex-wrap: wrap;
}
.techSupport_apiService .main_box {
  background: #FFFFFF;
  box-shadow: 0px 13.3657px 44.5523px rgba(160, 163, 189, 0.5);
  border-radius: 8.91046px;
  width: 466px;
  margin: 1rem 1rem 1rem 0;
}
.techSupport_apiService .main_box-top {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1.5px solid #F2F4F7;
}
.techSupport_apiService .main_box-top-title {
  display: flex;
  align-items: center;
}
.techSupport_apiService .main_box-top-title h5 {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  margin-left: 0.4rem;
  margin-bottom: 0;
}
.techSupport_apiService .main_box-top-refresh {
  display: flex;
  align-items: center;
  width: 156px;
  height: 32px;
  background: #0046E6;
  border: 1px solid #98A2B3;
  border-radius: 7.5px;
  padding: 0 0.4rem;
  cursor: pointer;
}
.techSupport_apiService .main_box-top-refresh p {
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  color: #FFFFFF;
  margin: 0 0.5rem;
}
.techSupport_apiService .main_box-body {
  display: flex;
  padding: 2rem 1rem;
  align-items: center;
}
.techSupport_apiService .main_box-body h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  margin-right: 1rem;
}
.techSupport_apiService .main_box-body .dataKey {
  position: relative;
}
.techSupport_apiService .main_box-body .dataKey input {
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  width: 305px;
  height: 37px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin-right: 0.8rem;
}
.techSupport_apiService .main_box-body .dataKey img {
  position: absolute;
  right: 20px;
  top: 12px;
  cursor: pointer;
}

.float_top {
  margin: 25px 0 0 40px;
  height: 45px;
  display: flex;
}
.float_top-toggle {
  display: flex;
  align-items: center;
}
.float_top-toggle p {
  margin: 0;
  padding-left: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #424242;
}
.float_top .active {
  border-bottom: 2.5px solid #427CFF;
  padding-bottom: 5px;
}
.float_top a {
  text-decoration: none;
  color: #424242;
}
.float_top p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #656565;
}
.float_top h1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 62px;
  color: #373737;
}
.float_request {
  width: 100%;
}
.float_request-top {
  display: flex;
  justify-content: space-between;
}
.float_request h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #0C0C0C;
  margin: 10px 0;
}
.float_request p {
  margin: 0;
}
.float_request-loan {
  display: flex;
  margin: 20px 0;
}
.float_request-loan .amount {
  border-left: 4.5px solid #D0D7E6;
  padding-left: 12px;
}
.float_request-loan .amount p {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #656565;
  margin-bottom: 1em;
}
.float_request-loan .amount h3 {
  font-weight: 500;
  font-size: 36px;
  line-height: 47px;
  color: #373737;
}
.float_request-loan .duration {
  border-left: 4.5px solid #D0D7E6;
  padding-left: 12px;
  margin-left: 3em;
}
.float_request-loan .duration p {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #656565;
  margin-bottom: 1em;
}
.float_request-loan .duration h3 {
  font-weight: 500;
  font-size: 36px;
  line-height: 47px;
  color: #373737;
}
.float_request-statement {
  margin: 2em 0;
}
.float_request-statement .box {
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
}
.float_request-statement .box p {
  margin: 0 35px 0 10px;
}
.float_request-statement .accountNumber {
  margin-left: 2em;
}
.float_request-statement .accountNumber h4 {
  margin: 0;
}
.float_request-statement .view {
  cursor: pointer;
  width: 40px;
}
.float_request-director .column {
  padding: 2em 0;
  border-top: 1px solid #DFDBDB;
  border-bottom: 1px solid #DFDBDB;
}
.float_request-director .column th {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  padding-right: 2em;
}
.float_request-director .column td {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #344054;
  padding-right: 2em;
}
.float_request-action {
  float: right;
  padding: 2em;
}
.float_request-action .decline {
  width: 185px;
  height: 48px;
  background: #D92D20;
  border: 1px solid #D92D20;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin-right: 1em;
}
.float_request-action .next {
  padding: 0 1rem;
  height: 48px;
  background: #0046E6;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  border: none;
  margin-right: 10px;
}
.float_offer {
  width: 100%;
  box-sizing: border-box;
}
.float_offer .amount {
  border-left: 4.5px solid #D0D7E6;
  padding-left: 12px;
  margin-bottom: 2em;
}
.float_offer .duration {
  border-left: 4.5px solid #D0D7E6;
  padding-left: 12px;
}
.float_offer-loan {
  padding: 41.5px 24px;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 13.3657px 44.5523px rgba(160, 163, 189, 0.5);
}
.float_offer-loan h5 {
  font-weight: 500;
  font-size: 21px;
  line-height: 27px;
  color: #000000;
  margin-bottom: 1em;
}
.float_offer-loan .form-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}
.float_offer-loan .form-input label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  margin-bottom: 10px;
}
.float_offer-loan .form-input input {
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  width: 100%;
  height: 38px;
  padding: 0 10px;
}
.float_offer-loan .form-input-select {
  margin: -20px 0 20px;
}
.float_offer-loan .form-breakdown {
  margin: 40px 0 15px;
}
.float_offer-loan .form-breakdown .column {
  padding: 2em 0;
  border-top: 1px solid #DFDBDB;
  border-bottom: 1px solid #DFDBDB;
}
.float_offer-loan .form-breakdown .column th {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  padding-right: 2em;
}
.float_offer-loan .form-breakdown .column td {
  font-weight: 400;
  font-size: 14.22px;
  line-height: 19px;
  color: #06152B;
}
.float_offer-loan button {
  background: #0046E6;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  padding: 8px 20px;
  border: none;
}
.float_modal {
  padding: 20px;
  box-sizing: border-box;
}
.float_modal-options {
  margin: 15px 0 0;
}
.float_modal-options h5 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  margin: -15px 0 20px;
}
@media (max-width: 560px) {
  .float_modal-options h5 {
    font-size: 20px;
    line-height: 28px;
  }
}
.float_modal-options p {
  font-weight: 500;
  font-size: 16px;
  line-height: 50px;
}
@media (max-width: 560px) {
  .float_modal-options p {
    font-size: 12px;
    line-height: 20px;
  }
}
.float_modal-options .radio [type=radio]:checked,
.float_modal-options .radio [type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.float_modal-options .radio [type=radio]:checked + label,
.float_modal-options .radio [type=radio]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #344054;
  font-weight: 600;
}
.float_modal-options .radio [type=radio]:checked + label:before,
.float_modal-options .radio [type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #0046E6;
  border-radius: 100%;
  background: #fff;
}
.float_modal-options .radio [type=radio]:checked + label:after,
.float_modal-options .radio [type=radio]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #0046E6;
  position: absolute;
  top: 2.7px;
  left: 3px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.float_modal-options .radio [type=radio]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.float_modal-options .radio [type=radio]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.float_modal-action {
  padding: 7px;
  text-align: center;
  color: white;
  background: #0046E6;
  border: 1px solid #0046E6;
  border-radius: 6px;
  width: 100%;
}
.float_modal-terms {
  margin: 40px 20px;
}
.float_modal-terms ::-webkit-scrollbar {
  width: 10px;
}
.float_modal-terms ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 10px;
}
.float_modal-terms ::-webkit-scrollbar-thumb {
  background: #EEEFF0;
  border-radius: 10px;
}
.float_modal-terms ::-webkit-scrollbar-thumb:hover {
  background: #EEEFF0;
}

.auth {
  width: 100%;
  box-sizing: border-box;
}
.auth_layout {
  box-sizing: border-box;
}
@media (min-width: 992px) {
  .auth_layout {
    display: flex;
    position: relative;
    width: 100%;
  }
}
.auth_section1 {
  width: 50vw;
  margin-bottom: 50px;
}
@media (max-width: 992px) {
  .auth_section1 {
    width: 100vw;
    height: 100vh;
  }
}
@media (max-width: 560px) {
  .auth_section1 {
    padding: 0 20px;
  }
}
.auth_section1_container {
  max-width: 450px;
  margin: auto;
  display: block;
}
.auth_section1_container-title {
  color: #515151;
  font-weight: 700;
}
.auth_section1_logo {
  width: 200px;
  margin: 70px 0 30px;
}
@media (max-width: 560px) {
  .auth_section1_logo {
    margin: 30px 0 30px;
  }
}
.auth_section2 {
  background-color: #0046E6;
  width: 50vw;
  position: fixed;
  right: 0;
  height: 100vh;
}
@media (max-width: 992px) {
  .auth_section2 {
    display: none;
  }
}
.auth_section2-pattern {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.auth_section2-pattern img {
  border: 14px solid rgba(48, 111, 255, 0.69);
  border-radius: 24px;
}
.auth_section2-pattern h3 {
  font-weight: 700;
  font-size: 29.5556px;
  line-height: 38px;
  color: #FFFFFF;
  margin-top: 1em;
}
.auth_section2-pattern p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
}
.auth_signup {
  margin-top: 100px;
  box-sizing: border-box;
}
.auth_signup h3 {
  font-weight: 700;
  font-size: 29.5556px;
  line-height: 38px;
  color: #3C3C3C;
}
.auth_signup p {
  font-weight: 500;
  font-size: 14.56px;
  line-height: 19px;
  margin-bottom: 1.5em;
  color: #808080;
}
.auth_signup label {
  width: 100%;
  font-weight: 500;
  font-size: 14.56px;
  line-height: 19px;
  margin-bottom: 0.5em;
  /* identical to box height */
  color: #4E4E4E;
}
.auth_signup img {
  margin-bottom: 1.5em;
}
.auth_signup input {
  width: 100%;
  margin: 10px 0;
  padding: 5px 15px;
  height: 40px;
  border: 2px solid #C4C4C4;
  border-radius: 5px;
  color: #8A8A8A;
}
.auth_signup input:focus {
  border: 2px solid #296AFC;
  outline: none !important;
}
.auth_signup-phone {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.auth_signup option {
  padding: 10px 15px;
  font-size: 2em;
}
.auth_signup-password {
  position: relative;
}
.auth_signup-password img {
  position: absolute;
  right: 20px;
  width: 20px;
  cursor: pointer;
}
.auth_signup-password .notvisible {
  top: 50%;
}
.auth_signup-password .visible {
  top: 45%;
}
.auth_signup-forgot {
  color: #0046E6 !important;
  margin-top: 10px;
}
.auth_signup-submit {
  width: 100%;
  padding: 10px 0;
  text-align: center;
  margin: 10px 0;
  border-radius: 7px;
  border: 1px solid #D0D0D0;
  background: #D0D0D0;
  font-size: 1.1em;
  font-weight: 600;
  color: white;
}
.auth_signup-active {
  width: 100%;
  padding: 10px 0;
  text-align: center;
  margin: 10px 0;
  border-radius: 7px;
  border: 1px solid #296AFC;
  background: #296AFC;
  font-size: 1.1em;
  font-weight: 600;
  color: white;
}
.auth_signup-text1 {
  text-align: center;
  margin-top: 10px;
  color: #292929;
}
.auth_signup-text1 span {
  text-decoration: underline;
  cursor: pointer;
}
.auth_otp {
  position: relative;
  margin-top: 40px;
}
.auth_otp input {
  margin: 10px 0px 10px 0;
  padding: 10px 15px;
  border: 2px solid #C4C4C4;
  border-radius: 5px;
  width: 50px !important;
  height: 45px !important;
  color: #8A8A8A;
}
@media (max-width: 450px) {
  .auth_otp input {
    width: 12% !important;
    height: 40px !important;
    margin: 10px 15px 10px 0 !important;
    padding: 5px !important;
  }
}
@media (max-width: 400px) {
  .auth_otp input {
    margin: 10px 10px 10px 0 !important;
  }
}
.auth_otp input:focus {
  border: 2px solid #296AFC;
  outline: none !important;
}
.auth_otp-show {
  width: 20px;
  position: absolute;
  top: 25px;
  right: 17px;
  cursor: pointer;
}
@media (max-width: 450px) {
  .auth_otp-show {
    top: 20px;
    right: 0;
  }
}
.auth_otp-text1 span {
  color: #296AFC;
  cursor: pointer;
}

.dashboard_sidebar {
  width: 200px;
  height: 100vh;
  position: fixed;
  padding: 20px 30px;
  top: 0;
  z-index: 3;
  left: 0;
  overflow-y: scroll;
  background-color: #0046E6;
  color: white;
  overflow-y: scroll;
}
@media (max-width: 992px) {
  .dashboard_sidebar {
    z-index: 3;
    left: -300px;
    transition: 2s;
  }
}
.dashboard_sidebar-cancel {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 35px;
}
@media (min-width: 992px) {
  .dashboard_sidebar-cancel {
    display: none;
  }
}
.dashboard_sidebar-logo {
  width: 150px;
  margin: 10px 0 30px;
}
.dashboard_sidebar hr {
  background-color: white;
}
.dashboard_sidebar-text1 {
  font-size: 0.9em;
  color: white;
  margin-bottom: 0 !important;
}
.dashboard_sidebar #mainBoard {
  cursor: pointer;
}
.dashboard_sidebar .mainBoard img {
  position: absolute;
  right: 15px;
  width: 10px;
  top: 20px;
}
.dashboard_sidebar .mainboard {
  display: flex;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  border-radius: 7px;
  padding: 10px 15px;
}
.dashboard_sidebar .showIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 7px;
  padding: 10px 15px;
  margin-bottom: 20px;
}
.dashboard_sidebar .icon_align {
  display: flex;
  width: 85px;
  justify-content: space-between;
}
.dashboard_sidebar-main {
  max-height: 0;
  overflow: hidden;
  background: #E5E5E5;
  transition: max-height 0.3s ease-out;
  /* overflow-y: scroll; */
}
.dashboard_sidebar-items {
  display: flex;
  border-radius: 7px;
  padding: 10px 15px;
  margin-bottom: 20px;
  position: relative;
}
.dashboard_sidebar-items img {
  width: 20px;
  margin: 0 20px 0 0;
}
.dashboard_sidebar-items p {
  margin: 0 !important;
  color: white;
}
.dashboard_sidebar-items span {
  color: #FFC702;
  font-size: 0.65em;
  position: absolute;
  bottom: 0;
  left: 55px;
}
.dashboard_sidebar-items:hover {
  background-color: #427CFF;
}
.dashboard_sidebar .active {
  background-color: #427CFF;
}
.dashboard_sidebar .logout {
  margin-bottom: 50px;
  cursor: pointer;
}
.dashboard_main {
  position: relative;
  top: 0;
  right: 0;
  margin-left: 260px;
}
@media (max-width: 992px) {
  .dashboard_main {
    margin-left: 0;
  }
}
.dashboard_main-navheader {
  margin: 0 0 0 40px;
  font-weight: 400;
  color: #000000;
}
.dashboard_main-navheader p {
  font-weight: 400;
  font-size: 17px;
}
.dashboard_main-navheader-caption {
  display: flex;
  align-self: center;
  margin: 20px 10px;
}
.dashboard_main-navheader-caption p {
  margin: 0 0 0 10px;
}
.dashboard_main-navheader-caption p a {
  text-decoration: none;
}
.dashboard_main-navheader-caption p span {
  color: #0046E6;
}
.dashboard_main-nav {
  display: flex;
  margin: 25px 0 0 40px;
  height: 65px;
}
@media (max-width: 992px) {
  .dashboard_main-nav {
    margin: 15px 0 0 0;
  }
}
.dashboard_main-nav-menu {
  width: 50px;
  position: absolute;
  top: 10px;
  left: 15px;
}
@media (min-width: 992px) {
  .dashboard_main-nav-menu {
    display: none;
  }
}
.dashboard_main-nav h4 {
  margin-top: 20px;
  font-weight: 700;
  font-size: 22px;
  position: absolute;
}
.dashboard_main-nav h4 span {
  font-size: 17px;
  margin: 0 10px;
  color: rgba(0, 0, 0, 0.367);
}
@media (max-width: 992px) {
  .dashboard_main-nav h4 {
    display: none;
  }
}
.dashboard_main-subnav {
  display: flex;
  margin: 25px 0 0 40px;
  height: 45px;
}
@media (max-width: 992px) {
  .dashboard_main-subnav {
    margin: 15px 0 0 0;
  }
}
.dashboard_main-subnav-menu {
  width: 50px;
  position: absolute;
  top: 10px;
  left: 15px;
}
@media (min-width: 992px) {
  .dashboard_main-subnav-menu {
    display: none;
  }
}
.dashboard_main-subnav h4 {
  margin-top: 20px;
  font-weight: 700;
  font-size: 22px;
  position: absolute;
}
.dashboard_main-subnav h4 span {
  font-size: 17px;
  margin: 0 10px;
  color: rgba(0, 0, 0, 0.367);
}
@media (max-width: 992px) {
  .dashboard_main-subnav h4 {
    display: none;
  }
}
.dashboard_main-live {
  display: flex;
  position: relative;
  flex-direction: column;
  /* width: 100%; */
  justify-content: flex-end;
  margin: 0 50px 0 auto;
}
@media (max-width: 992px) {
  .dashboard_main-live {
    margin: 0 50px 0 auto;
  }
}
.dashboard_main-live .button {
  margin-top: 17px;
}
@media (max-width: 769px) {
  .dashboard_main-live .button {
    display: none;
  }
}
.dashboard_main-live-text {
  margin: 20px 15px 0 10px;
  color: #2BBD35;
}
@media (max-width: 769px) {
  .dashboard_main-live-text {
    display: none;
  }
}
.dashboard_main-live-profile {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 156px;
}
.dashboard_main-live-profile-pic {
  width: 39px;
  border-radius: 50%;
  overflow: hidden;
}
.dashboard_main-live-profile-pic img {
  width: 100%;
  height: auto;
}
.dashboard_main-live-profile-icon {
  width: 8.49px;
}
.dashboard_main-live-profile-icon img {
  width: 100%;
  height: auto;
  cursor: pointer;
}
.dashboard_main-live-profile-name h6 {
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
  margin-bottom: 0;
  /* identical to box height */
  display: flex;
  align-items: center;
  color: #000000;
}
.dashboard_main-live-user {
  display: flex;
  border-radius: 10px;
  box-shadow: 3px 4px 18px rgba(0, 0, 0, 0.1);
  padding: 5px 10px !important;
  position: absolute;
  top: 70px;
  right: 5px;
  width: 228px;
  height: 91px;
  align-items: center;
  justify-content: space-around;
  z-index: 10;
  background: #ffffff;
}
.dashboard_main-live-user-box {
  position: relative;
  margin-right: 15px;
}
.dashboard_main-live-user-pic {
  width: 39px;
  height: 39px;
  border-radius: 50%;
  overflow: hidden;
}
.dashboard_main-live-user-pic img {
  width: 100%;
  height: auto;
}
.dashboard_main-live-user-status {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #087F00;
  position: absolute;
  bottom: 0;
  right: 0;
}
.dashboard_main-live-user-name {
  text-align: center;
}
.dashboard_main-live-user-name h6 {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #2C3E50;
  text-transform: capitalize;
  margin: 10px 0 0 !important;
}
.dashboard_main-live-user-name p {
  margin-bottom: 0 !important;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #696969;
}
.dashboard_main-live-user-name-verified {
  display: flex;
  align-items: center;
  border-radius: 30px;
  padding: 2px 5px;
  border: 0.25px solid #0046E6;
  border-radius: 14px;
  width: 130px;
  margin-top: 3px;
  justify-content: center;
}
.dashboard_main-live-user-name-verified img {
  width: 15px;
  margin: 0 5px 0 0;
}
.dashboard_main-live-user-name-verified p {
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  color: #1F1F1F;
}
@media (max-width: 769px) {
  .dashboard_main-live-user-name {
    display: none;
  }
}
.dashboard_main-live-user-arrow {
  margin-left: 10px;
}
.dashboard_main-live-more {
  border-radius: 8px 8px 0px 22px;
  top: 70px;
  right: 0px;
  position: absolute;
  background-color: white;
  width: calc(100% - 30px);
  padding: 10px 15px;
  box-shadow: 3px 4px 18px rgba(0, 0, 0, 0.1);
}
.dashboard_main-live-more p {
  font-size: 0.9em;
  color: #2C2C2C;
  margin: 5px 0 0;
}
.dashboard_main-live-more div {
  display: flex;
}
.dashboard_main-live-more div img {
  margin-left: auto;
  width: 12px;
}/*# sourceMappingURL=main.css.map */