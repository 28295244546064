.compliance{
    margin-top: 30px;
    @media(max-width: $mobile){
        margin: 30px 20px 40px;
    }

    &_top{
        display: flex;
        margin: auto;
        justify-content: center;
        
        &-circle{
            background: linear-gradient(128.58deg, #0FCD16 14.67%, #2BBD35 86.8%);
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 30px;

            @media(max-width: $mobile) {
                display: none;
            }
        }
        
        &-progress{
            border-top: 2px dashed #629C66;
            width: 300px;
            position: relative;
            margin-top: 20px;
            
            @media(max-width: $mobile) {
                width: 75%;
                margin: 20px auto;
            }

            img{
                width: 20px;
                position: absolute;
                top: -12px;
                background-color: white;
            }

            p{
                position: absolute;
                font-size: 1em;
                color: $color-text4;
                top: 10px;
                
                @media(max-width: $mobile) {
                    font-size: 0.9em;
                }
            }

            .icon1{
                left: 0;
            }

            .icon2{
                left: 140px;
                @media(max-width: $mobile) {
                    left: calc(50% - 10px);
                }
            }

            .icon3{
                right: 0;
            }

            .text1{
                left: -10px;
            }

            .text2{
                left: 120px;
                @media(max-width: $mobile) {
                    left: calc(50% - 30px);
                }
            }
            .text3{
                right: -40px;
                @media(max-width: $mobile) {
                    right: -30px;
                }
            }
        }
    }

    &_main{
        // border: 1px solid $color-sidebar;
        margin: 40px auto 0;
        display: block;
        padding: 20px;
        max-width: 500px;
        border-radius: 10px;

        // &-infoBg{
        //     background-color: #F5F5F5;
        // }
    }

    &_save{
        width: 540px;
        padding: 10px 0;
        text-align: center;
        display: block;
        margin: 20px auto 30px;
        border-radius: 7px;
        border: 1px solid $color-sidebar;
        background: $color-sidebar;
        font-size: 1.1em;
        font-weight: 600;
        color: white;

        @media(max-width: $mobile){
            width: 100%;
        }
    }

    &_profile{
        &-form{
            box-sizing: border-box;

            label{
                font-weight: 600;
                font-family: $font-main;
                margin: 20px 0 5px;
            }
            p, label{
                color: $color-text5;
                font-size: 1em;
            }
            input{
                width: 100%;
                height: 2.5em;
                padding: 5px 15px;
                border: 2px solid #DADADA;
                box-sizing: border-box;
                border-radius: 4px;

                &:focus{
                    border: 2px solid $color-sidebar;
                    outline: none;
                }
            }
        }

        &-info{
            h6{
                color: #5B5B5B;
                margin: 0 !important;
                font-size: 1em;
            }
            p{
                color: $color-text5;
                font-size: 1em;
            }
            button{
                background: #EAEAEA;
                border: 1px solid #696969;
                border-radius: 8px;
                text-align: center;
                padding: 8px 15px;
                width: 50px;
                font-weight: 700;
            }

            &-next{
                float: right;
            }
            
        }
    }
    
    &_alldone{
        margin: 20px auto;
        text-align: center;
    }
}