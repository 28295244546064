@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,h2, h3,h4, h5,h6{
  font-family: 'DM Sans', sans-serif;
}

p, span, a, button{
  font-family: 'Mulish', sans-serif;
}

input:focus, select:focus{
  outline: none;
}

.link-decor{
  text-decoration: none;
}




/* Hide scroll bar */
.hide-scrollBar::-webkit-scrollbar {
  display: none;
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
.hide-horizontalScrollBar::-webkit-scrollbar {
  height: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}



/* Style for toggle button to switch betwen live and test */
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  width: 60px;
  height: 28px;
  /* margin: -20px auto 0 auto; */
  overflow: hidden;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #CDCDCD;
  transition: 0.3s ease all;
  z-index: 1;
}

.button-1 .knobs:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 6px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: white;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

.button-1 .checkbox:checked + .knobs:before {
  content: "";
  left: 33px;
  background-color: white;
}

.button-1 .checkbox:checked ~ .layer {
  background-color: #2BBD35;
}

.button-1 .knobs,
.button-1 .knobs:before,
.button-1 .layer {
  transition: 0.3s ease all;
}


/* To move the virtual cards on income page */
.cards-left-arrow {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  height: 220px;
  width: 50px;
  z-index: 2000;
  cursor: pointer;
}
.cards-right-arrow {
  background: rgba(0, 0, 0, 0.3);
  right: 0;
  position: absolute;
  height: 220px;
  width: 50px;
  z-index: 2000;
  cursor: pointer;
}
.cards-left-arrow img, .cards-right-arrow img {
  display:block;
  margin: 100px auto 0;
}
.card-scroll{
  display: flex;
  overflow-x: scroll;
}
.card-scroll::-webkit-scrollbar {
  width: 0;  
  background: transparent; 
}


/* Line loader loop for tables */
.loader-loop {
  animation: 1.5s infinite loop cubic-bezier(0.420, 0.000, 0.580, 1.000);
}
@keyframes loop {
  0% {
    width: 0%;
    transform: translateX(0%);
  }
  7% {
    width: 10%;
    transform: translateX(0%);
  }
  40% {
    width: 60%;
  }
  50% {
    transform: translateX(85%);
  }
  
  75% {
    width: 100%;
  }
  100% {
    transform: translateX(100%);
  }
}



/* date range style override */
.rdrMonth {
  width: 100% !important;
}

/* react toggle style override */
.react-toggle-thumb {
  box-shadow: none !important;
}
/* revenue section toggle */
.revenue-toggle .react-toggle-track{
  background-color: #2BBD35 !important;
}

.revenue-toggle.react-toggle--checked .react-toggle-track {
  background-color: #2BBD35 !important;
}