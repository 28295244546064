.settings{
    &_navMain{
        border-bottom: 1px solid #B5B5B5;
        display: flex;
        padding: 0 30px;
        @media(max-width: $tab-port){
            padding: 0 10px;
        }

        p{
            margin: 0 20px;
            padding: 20px 10px 10px;
            cursor: pointer;
            color: black;
            @media(max-width: $tab-port){
                padding: 10px 5px 7px;
                font-size: 0.9em;
                margin: 0 10px;
            }
        }

        .active{
            border-bottom: 3px solid $color-sidebar;
        }
    }
    &_subNav{
        border-bottom: 1px solid #B5B5B5;
        display: flex;
        padding: 10px 30px 0 10px;
        margin-left: 30px;
        @media(max-width: $tab-port){
            margin-left: 0px;
        }

        p{
            margin: 0 20px;
            color: black;
            padding: 20px 10px 10px;
            cursor: pointer;
            @media(max-width: $tab-port){
                padding: 10px 5px 7px;
                font-size: 0.9em;
                margin: 0 10px;
            }
        }
        
        .active{
            border-bottom: 3px solid $color-sidebar;
        }
    }

    &_members{
        box-sizing: border-box;
        margin: 100px 60px;
        
        &-table{
            border: 0.25px solid #0046E6;
            box-sizing: border-box;
            box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
            border-radius: 10px;
            width: 90%;
            padding: 15px 0px;
            margin: 30px 0;
            
            
            @media(max-width: $tab-land){
                width: 100%;
                overflow-x: scroll;
                margin: 50px 0px;
                max-width: 90% !important;
            }

            &-data{
                @media(max-width: $tab-land){
                    min-width: 950px;
                }
            }

            &-top{
                display: flex;
                margin: 0 25px;

                h3{
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 26px;
                    /* identical to box height */

                    letter-spacing: 0.02em;

                    color: rgba(28, 28, 28, 0.85);
                }

                &-btn{
                    margin-left: auto;
                    display: flex;

                    &-form{
                        width: 270px;
                        position: relative;
                        padding: 0 !important;
                        margin: 0 15px 0 0;

                        input{
                            width: 100%;
                            height: 30px;
                            background: #F5F5F5;
                            border: 1px solid #DADADA;
                            box-sizing: border-box;
                            border-radius: 4px;
                            padding: 5px 10px 5px 30px;
                            font-size: 0.9em;
                            color: #797979;
                        }

                        img{
                            position: absolute;
                            top: 8px;
                            left: 6px;
                            width: 15px;
                        }
                    }

                    button{
                        height: 30px;
                        border: 1px solid $color-sidebar;
                        color: white;
                        background: $color-sidebar;
                        width: 200px;
                        padding: 0px 15px;
                        font-size: 0.9em;
                        border-radius: 6px;
                    }
                }
            }

            &-main{
                padding: 0 20px;

                &-action{
                    button{
                        border: 1px solid #DADADA;
                        border-radius: 4px;
                        padding: 5px;
                        margin-right: 10px;
                        background-color: white;
                        color: #797979;
                    }
                }

                &-status{
                    color: $color-online;
                }
            }

            table{
                width: 100%;
        
                tr{
                    border-bottom: 0.75px solid #B5B5B5;
                    color: #0F123F;
                    font-size: 1em;
                }
                th, td{
                    padding: 10px 0;
                }
                th{
                    opacity: 0.56;
                    font-weight: 400;
                    text-transform: capitalize !important;
                }
            }
        }
    }

    &_roles{
        margin: 0 30px;

        &-default{
            font-weight: 700;
        }

        &-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 20px;

            &-icon{
                width: 50px;
                height: 50px;
                overflow: hidden;
                margin-right: 10px;
                border-radius: 50%;

                img{
                    width: 20px;
                    margin: 14px 0 0 14px;
                }
            }

            &-text{
                width: 80%;
                .title{
                    color: $color-textMain;
                    margin: 0;
                    
                }
                .desc{
                    color: #797979;
                    width: 100%;
                    margin: 0 0 5px 0;
                }
            }

            button{
                margin-left: auto;
                border: 1px solid #DADADA;
                box-sizing: border-box;
                border-radius: 4px;
                background: white;
                height: 35px;
                width: 158px;
                font-size: 0.9em;
                color: #797979;
                padding: 4px;
            }
        }

        .cards{
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin: 4em 0;

            &-green{
                width: 365.5px;
                height: 532px;
                background: #FAFAFA;
                border: 0.4px solid #0046E6;
                border-radius: 11px;

                &-top{
                    background: rgba(43, 189, 53, 0.19);
                    border: 0.4px solid #0046E6;
                    border-radius: 11px 11px 0px 0px;
                    height: 72.57px;
                    display: flex;
                    align-items: center;

                    p{
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 26px;
                        letter-spacing: 0.02em;
                        color: #2BBD35;
                        margin: 0 0 0 20px;
                    }
                }
              
            }

            &-red{
                width: 365.5px;
                height: 532px;
                left: 431.5px;
                top: 513.5px;
                background: #FAFAFA;
                border: 0.4px solid #0046E6;
                border-radius: 11px;

                &-top{
                    height: 72.57px;
                    left: 837.5px;
                    top: 513.5px;
                    background: rgba(255, 0, 0, 0.15);
                    border: 0.4px solid #0046E6;
                    border-radius: 11px 11px 0px 0px;
                    display: flex;
                    align-items: center;

                    p{
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 26px;
                        letter-spacing: 0.02em;
                        color: rgba(255, 0, 31, 0.85);
                        margin: 0 0 0 20px;
                    }
                }

                 
            }

        }

        .custom{
            color: #0046E6;
            cursor: pointer;
            font-weight: 700;
        }
    }

    &_profile{
        &-form{
            padding: 2em 5em;

            &-div{
                display: flex;
                align-items: center;
                justify-content: space-between;

                .input{
                    width: 326px;
                    height: 48px;
                    border: 1px solid #DADADA;
                    color: $color-formInput;
                    padding: 5px 10px;
                    margin: 10px 0;
                    border-radius: 7px;
                    display: flex;
                    align-items: center;

                    input{
                        border: none;
                        margin-left: 20px;
                        width: 100%;
                    }
                }
                
            }
            label{
               
                font-weight: 500;
                font-size: 21px;
                line-height: 27px;
            }

            &-btn1{
                width: 180px !important;
                border: 1px solid #DADADA !important;
                color: #2E2E2E !important;
                font-size: 0.9em;
                background-color: white !important;
                margin: 5px 0 0 !important;
            }
            
        }
    }
}