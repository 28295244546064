.table{
    // border: 0.25px solid #0046E6;
    box-sizing: border-box;
    box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    width: 100%;
    padding: 5px 0px;
    margin-top: 20px;
    // text-transform: capitalize;

    @media(max-width: 1400px){
        overflow-x: scroll;
    }

    table{
        width: 100%;
        @media(max-width: 1400px){
            min-width: 1400px;
        }

        tbody{
            border: none !important;

            tr{
                /* Light Version/Neutral */
                border-radius: 10px;
                box-shadow: -1px 4px 17px -11px rgba(0, 0, 0, 0.25);
                border-radius: 8.91046px;
                margin-bottom: 15px;
                padding: 10px !important;
                height: 70px;
            }
        }
        thead{
            border: 1px solid #D8D8D8;
            background: #F0F0F0;
            height: 48px;
            border-radius: 4.5px;
            padding: 20px 10px;
        tr{
            border-bottom: 0;
            font-size: 1em;


            @media(max-width: $mobile){
                font-size: 0.9em;
            }
        }
        th{
            font-weight: 700;
            font-size: 14px;
            line-height: 21px;
        }
        }
        th, td{
            padding: 8px ;

        }
        td{
            font-weight: 400;
            font-size: 14.483px;
            line-height: 19px;
            color: #06152B;
            
        }
        th{
            opacity: 0.56;
            font-weight: 400;
            text-transform: capitalize !important;
            font-weight: 400;
            font-size: 12.8738px;
            line-height: 17px;
            /* identical to box height */


            /* Light Version/Text */

            color: #06152B;
        }
    }
    &-bluebox{
        width: 100px;
        height: 30.99px;
        background:#DCE7FF;
        display: flex;
        align-items:center;
        justify-content:center;
        border-radius: 8.26269px;
        color: #3A36DB

    }

    &-pendingBox{
        width: 63px;
        width: 136.5px;
        background: #ffebf4;
        display: flex;
        align-items:center;
        justify-content:center;
        border-radius: 19.2361px;
        color: #FF69B4;
        padding: 5px 10px;                                            
    }
    &-approvedBox{
        width: 136.5px;
        height: 30.99px;
        background: #dcfffd;
        display: flex;
        align-items:center;
        justify-content:center;
        border-radius: 19.2361px;
        color: #03A89E;
        padding: 5px 10px;
                                                     
    }
    &-requestBox{
        padding: 6px 11px;
        height: 32px;
        background: #0046E6;
        border-radius: 6px;
        font-size: 15.22px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF; 
        cursor: pointer;                       
    }

    &-walletCredit{
        color: #03A89E !important;                           
    }
    &-walletDebit{
        color: #FF69B4 !important;                          
    }

    &_income{
        &-btn{
            button{
                font-weight: 500;
                font-size: 15.22px;
                line-height: 20px;
                padding: 6px 11px;
                color: white;
                border: 1px solid transparent;
                border-radius: 6px;
                // text-align: left;
                width: 83px;
                img{
                    width: 20px;
                }
            }

            .view{
                background: #47D800;
                margin-right: 10px;
                // width: 100px;
                // img{
                //     margin-bottom: 3px;
                //     margin-right: 8px;
                // }
            }
            .see{
                background: #0046E6;
                margin-right: 10px;
                width: 39px;
                height: 32px;
                padding: 0 !important;
                
                // width: 100px;
                img{
                    margin-bottom: 2px;
                    // margin-right: 15px;
                }
            }
            .reload{
                
            background: #FB2C2C;
                // img{
                //     margin-right: 5px;
                // }
            }
            .share{
                background-color: $color-sidebar;
                img{
                    margin: -3px 5px 0 0;
                    width: 15px;
                }
            }
            .link{
                background-color: #47D800;
                margin-right: 10px;
                img{
                    margin-bottom: 3px;
                    margin-right: 8px;
                }
            }
        }
    }

    &_audit{
        .green{
            color:#47D800;
        }
        .red{
            color: #FF2608;
        }
        &-btn{
            button{
                font-size: 0.9em;
                color: white;
                padding: 4px 10px;
                border-radius: 5px;
                text-transform: capitalize;
            }

            .success{
                background: #47D800;
                border: 1px solid #47D800;
            }

            .failed{
                background: #FF2608;
                border: 1px solid #FF2608;
            }
        }
    }

    &_page{
        display: flex;
        justify-content: center;
        margin: 15px auto 0;
        .active{
            background: #DCE7FF;
            color: #0046E6 !important;
        }

        p{
            padding: 5px 10px;
            margin: 0;
        }
         
        &-num{
            color: #667085;
            width: 38px;
            flex-shrink: 0;
            margin: 0 2px;
            cursor: pointer;
            text-align: center;
            border-radius: 7px;
        }

    }

    &_view{
        text-align: center;
        margin-top: 20px;
        
        button{
            width: 100px;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #D0D5DD;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
        }
     }
}