.dashboard{
    &_sidebar{
        width: 200px;
        height: 100vh;
        position: fixed;
        padding: 20px 30px;
        top: 0;
        z-index: 3;
        left: 0;
        overflow-y: scroll;
        background-color: $color-sidebar;
        color: white;
        overflow-y: scroll;
        
        @media(max-width: $tab-land){
            z-index: 3;
            left: -300px;
            transition: 2s;
        }

        &-cancel{
            position: absolute;
            top: 20px;
            right: 20px;
            width: 35px;
            @media(min-width: $tab-land){
                display: none;
            }
        }

        &-logo{
            width: 150px;
            margin: 10px 0 30px;
        }
        hr{
            background-color: white;
        }
        &-text1{
            font-size: 0.9em;
            color: white;
            margin-bottom: 0 !important;
        }

        #mainBoard{
            cursor: pointer;
        }

        .mainBoard{ 
            img {
                position: absolute;
                right: 15px;
                width: 10px;
                top: 20px;
            }
        }

        .mainboard{
            display: flex;
            justify-content: space-between;
            position: relative;
            cursor: pointer;
            border-radius: 7px;
            padding: 10px 15px;
        }

        .showIcon {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            border-radius: 7px;
            padding: 10px 15px;
            margin-bottom: 20px;
        }

        .icon_align {
            display: flex;
            width: 85px;
            justify-content: space-between;
        }

        &-main{
            max-height: 0;
            overflow: hidden;
            background: #E5E5E5;
            transition: max-height 0.3s ease-out;
            /* overflow-y: scroll; */
        }

        &-items{
            display: flex;
            border-radius: 7px;
            padding: 10px 15px;
            margin-bottom: 20px;
            position: relative;

            img{
                width: 20px;
                margin: 0 20px 0 0;
            }
            p{
                margin: 0 !important;
                color: white;
            }

            span{
                color: #FFC702;
                font-size: 0.65em;
                position: absolute;
                bottom: 0;
                left: 55px;
            }

            &:hover{
                background-color: $color-activeLink;
            }
        }

        .active{
            background-color: $color-activeLink;
        }

        .logout{
            margin-bottom: 50px;
            cursor: pointer;
        }
    }
    &_main{
        &-navheader{
            margin: 0 0 0 40px;
            font-weight: 400;
            color: #000000;

            p{
                font-weight: 400;
                font-size: 17px;
            }

            &-caption{
                display: flex;
                align-self: center;
                margin: 20px 10px;

                p{
                    margin: 0 0 0 10px;

                    a{
                        text-decoration: none;
                    }

                    span{
                        color:#0046E6
                    }
                }
            }
        }

        &-nav{
            &-menu{
                width:50px;
                position: absolute;
                top: 10px;
                left: 15px;
                @media(min-width: $tab-land){
                    display: none;
                }
            }
        }
        
        &-live{
            display: flex;
            position: relative;
            flex-direction: column;
            /* width: 100%; */
            justify-content: flex-end;
            margin: 0 50px 0 auto;
            @media(max-width: $tab-land){
                margin: 0 50px 0 auto;
                // width: 300px;
            }

            .button{
                margin-top: 17px;
                @media(max-width: 769px){
                    display: none;
                }
            }

            &-text{
                margin: 20px 15px 0 10px;
                color: $color-toggleGreen;
                @media(max-width: 769px){
                    display: none;
                }
            }
            
            &-user{
                display: flex;
                border-radius: 10px;
                box-shadow: 3px 4px 18px rgba(0, 0, 0, 0.1);
                padding: 5px 10px !important;
                position: absolute;
                top: 70px;
                right: 5px;
                width: 228px;
                height: 91px;
                align-items: center;
                justify-content: space-around;
                z-index: 10;
                background: #ffffff;
                

                &-box{
                    position: relative;
                    margin-right: 15px;
                }

                &-pic{
                    width: 39px;
                    height: 39px;
                    border-radius: 50%;
                    overflow: hidden;

                    img{
                        width: 100%;
                        height: auto;
                    }
                }

                &-status{
                    width: 13px;
                    height: 13px;
                    border-radius: 50%;
                    background-color: $color-online;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }

                &-name{
                    text-align: center;
                    
                    h6{
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 23px;
                        color: #2C3E50;
                        text-transform: capitalize;
                        margin: 10px 0 0 !important;
                    }
                    p{
                        margin-bottom: 0 !important;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;
                        color: #696969;
                    }
                    &-verified{
                        display: flex;
                        align-items: center;
                        border-radius: 30px;
                        padding: 2px 5px;
                        border: 0.25px solid #0046E6;
                        border-radius: 14px;
                        width: 130px;
                        margin-top: 3px;
                        justify-content: center;

                        img{
                            width: 15px;
                            margin: 0 5px 0 0;
                        }

                        p{
                            font-weight: 400;
                            font-size: 10px;
                            line-height: 13px;
                            color: #1F1F1F;
                        }
                    }
                    
                    @media(max-width: 769px){
                        display: none;
                    }
                }

                &-arrow{
                    margin-left: 10px;
                }
            }

            &-more{
                border-radius: 8px 8px 0px 22px;
                top: 70px;
                right: 0px;
                position: absolute;
                background-color: white;
                width: calc(100% - 30px);
                padding: 10px 15px;
                box-shadow: 3px 4px 18px rgba(0, 0, 0, 0.1);

                p{
                    font-size: 0.9em;
                    color: $color-text3;
                    margin: 5px 0 0;
                }
                div{
                    display: flex;
                    img{
                        margin-left: auto;
                        width: 12px;
                    }
                }
            }
        }
    }
}