.bankAccs{
    display: flex;
    margin: 0 0 10px;
    align-items: center;
    &_pic{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        img{
            width: 100%;
        }
    }
    p{
        font-weight: 400;
        font-size: 17px;
    }
    &_name{
        margin: 0 0 0 10px;
    }
    &_accNo{
        margin: 0 0 0 auto;
    }
}