.apps{
    &_main{
        display: flex;
        flex-wrap: wrap;
        @media(max-width: $mobile){
            margin: 0px 30px 0 0;
        }

        a{
            text-decoration: none;
            color: #0F123F;
          
        }

        &__button{
            position: absolute;
        }

        &-box{
            border: 0.25px solid #0046E6;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 20px 15px;
            margin: 15px;
            display: flex;
            align-items: center;
            width: 250px;
            height: 150px;
            
            button{
                position: relative;
               padding: 2px;
               border-radius: 5px;
               width: 40px;
               opacity: 0.7;
               margin-top: 30px;
               /* z-index: 10; */
               /* display:none */
            }

            &-picture{
                width: 45px;
                height: 45px;
                overflow: hidden;
                border-radius: 50%;

                img{
                    width: 100%;
                    height: auto;
                }
            }

            &-text{
                margin: 0 0 0 15px;
                font-size: 20px;
                p{
                    margin: 0
                  
                }
                span{
                    color: #00B412;
                    font-size: 0.8em;
                    cursor: pointer;
                }
            }
        }

        &-box1{
            border: 0.25px solid #0046E6;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 30px 30px 20px 30px;
            margin: 15px;
            display: flex;

            @media(max-width: $tab-port){
                width: 100%;
            }

            &-picture{
                width: 45px;
                height: 45px;
                overflow: hidden;
                border-radius: 50%;
                background: linear-gradient(128.58deg, #0FCD16 14.67%, #2BBD35 86.8%);

                img{
                    width: 15px;
                    margin: 15px 0 0 15px;
                }
            }

            &-text{
                font-size: 1em;
                margin: 10px 0 0 10px;
            }
        }
    }

    &_create{
        border: 1px solid #0046E6;
        box-sizing: border-box;
        border-radius: 15px;
        box-sizing: border-box;
        width: 625px;
        padding: 40px 25px;
        margin: 60px auto;

        h4{
            text-align: center;
            font-weight: 700;
            font-size: 25px;
            line-height: 33px;
        }

        p{
            font-weight: 500;
            font-size: 21px;
            line-height: 27px;
        }

        &-header{
            display: flex;
            justify-content: space-between;
            margin: 2.5em 0;

            .flex{
                display: flex;

                a{
                    text-decoration: none;
                }
               
                    
                    &-roles{
                        border: 0.3px solid #000000;
                        border-radius: 7.76472px;
                        width: 129.5px;
                        height: 31.06px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                    p{
                        font-weight: 500;
                        font-size: 12.4235px;
                        line-height: 16px;
                        margin: 0;
                        color: #998F8F;
                        margin-left: 8px;
                     }
                    }

                    &-invite{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 129.5px;
                        height: 31.06px;
                        background: #3A36DB;
                        border-radius: 7.76472px; 
                        margin-left: 10px;
                        cursor: pointer;
                    
                    p{
                        font-weight: 500;
                        font-size: 12.4235px;
                        line-height: 16px;
                        margin: 0;
                        color: #ffffff;
                        margin-left: 8px;
                    }
                }
            }
        }

        @media(max-width: $mobile){
            width: 90%;
            margin: 30px 60px 30px 0px;
        }
        &-input{
            width: 100%;
            height: 48px;
            border: 1px solid #DADADA;
            color: $color-formInput;
            padding: 5px 15px;
            margin: 10px 0;
            border-radius: 7px;
        }
        &-button{
            padding: 5px 20px;
            border-radius: 7px;
            width: 100%;
            margin: 20px 0;
            color: white;
            background: $color-sidebar;
            border: 1px solid $color-sidebar;
        }
    }

    &_keys{
        margin: 0px 70px 30px 40px;
        color: $color-textMain;
        box-sizing: border-box;

        @media(max-width: $mobile){
            margin: 30px 20px 30px 0px;
        }

        &-topText {
            margin: 10px 0;
            p {
                font-weight: 400;
              font-size: 12px;
              line-height: 14px;
            }
        }

        &-box{
            border: 1px solid #0046E6;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 15px;
            display: flex;
            margin: 0 0 30px;
            
            p{
                margin-bottom: 0 !important;
            }
            img{
                width: 40px;
                margin-right: 25px;
            }
        }
        &-box1{
            box-sizing: border-box;
            border-radius: 10px;
            padding: 15px;
            margin: 0 0 30px;

            p{
                margin-bottom: 0 !important;
            }
        }

        &-box2{
            border: 1px solid #0046E6;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 15px;
            hr{
                margin: 0 0 10px !important;
            }

            @media(max-width: $desktop){
                margin-bottom: 30px;
            }

            &-title{
                display: flex;
                margin-bottom: 20px;
                
                h5{
                    font-weight: 700;
                    margin-top: 4px;
                    font-size: 1.3em;

                }

                button{
                    border: 1px solid #0046E6;
                    border-radius: 5px;
                    font-size: 0.8em;
                    padding: 3px 9px;
                    background: white;
                    margin-left: auto;

                    img{
                        width: 12px;
                        margin: -1px 5px 0 0;
                    }
                }
            }
            
            &-data{
                display: flex;
                
                p{
                    width: 100px;
                    margin-top: 3px;
                    flex-shrink: 0;
                }

                &-key{
                    position: relative;
                    width: 100%;

                    input{
                        border: 2px solid $color-sidebar;
                        border-radius: 4px;
                        padding: 1px 10px;
                        font-size: 11px;
                        height: 30px;
                        width: 100%;
                        background: #ECECEC;
                    }
                    &-cover{
                        border: 2px solid $color-sidebar;
                        border-radius: 0 4px 4px 0;
                        background: #ECECEC;
                        height: 30px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        padding: 0 15px;
                    }
                    img{
                        position: absolute;
                        top: 7px;
                        right: 7px;
                        width: 18px;
                    }
                }

                &-copy{
                    margin: -13px 0 0 15px;
                }
            }
        }

        
    }
}