.income{
    &_actions{
        margin: 40px;
        @media(min-width: $tab-port){
            display: flex;
        }

        &-box{
            border: 0.25px solid $color-sidebar;
            box-sizing: border-box;
            box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
            border-radius: 10px;
            width: 300px;
            padding: 15px;
            text-align: center;
            margin: 0 40px 0 0;

            @media(max-width:$tab-port){
                width: 100%;
                margin-bottom: 30px;
            }
        }
        &-radar{
            border: 0.25px solid $color-sidebar;
            box-sizing: border-box;
            box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
            border-radius: 10px;
            width: 300px;
            padding: 15px;
            margin: 0 40px 0 0;

            @media(max-width:$tab-port){
                width: 100%;
                margin-bottom: 30px;
            }

            .comingSoon{
                display: flex;
                p{
                    color: white;
                    background:#FFC702;
                    font-size: 0.75em;
                    border-radius: 5px;
                    height: 1.4em;
                    padding: 0px 2px !important;
                    margin: 12px 0 0 7px;
                }
            }
            
            input{
                width: 100%;
                margin: 10px 0 20px;
                padding: 5px 15px;
                height: 40px;
                border: 2px solid #C4C4C4;
                border-radius: 5px;
                color: $color-formInput;
            }

            button{
                width: 100%;
            }
        }
        h4{
            font-size: 1.2em;
            margin: 10px 0 !important;
        }
        button{
            border: 1px solid $color-sidebar;
            border-radius: 8px;
            background-color: $color-sidebar;
            padding: 8px 15px;
            color: white;
            margin: 0 0 15px;
            text-align: center;
            font-weight: 500;
        }

        &-radarNumber{
            border: 0.25px solid $color-sidebar;
            box-sizing: border-box;
            box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
            border-radius: 10px;
            width: 300px;
            padding: 15px;
            margin: 0 ;
        

            @media(max-width:$tab-port){
                width: 100%;
                margin-bottom: 30px;
            }

            .comingSoon{
                display: flex;
                p{
                    color: white;
                    background:#FFC702;
                    font-size: 0.75em;
                    border-radius: 5px;
                    height: 1.4em;
                    padding: 0px 2px !important;
                    margin: 12px 0 0 7px;
                }
            }
            
            input{
                width: 100%;
                margin: 10px 0 20px;
                padding: 5px 15px;
                height: 40px;
                border: 2px solid #C4C4C4;
                border-radius: 5px;
                color: $color-formInput;
            }

            button{
                width: 100%;
            }
        }
    }

    &_cards{
        &-front{
            width: 400px;
            height: 220px;
            color: white;
            border-radius: 15px;
            padding: 20px;

            &-logo{
                width: 50px;
                height: 50px;
                overflow: hidden;
                border-radius: 50%;
                img{
                    width: 100%;
                }
            }

            &-text{
                margin: 0px 10px;
                p{
                    margin: 0 !important;
                    font-size: 0.9em;
                }
                h4{
                    margin: 0 !important;
                    font-size: 1.3em;
                }
            }
        }
    }

}