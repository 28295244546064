.techSupport{
    &_table{
        margin: 0 40px;
    }

    &_details{
        width: 450px;
        border: 1px solid #E7E7E7;
        border-radius: 7px;
        padding: 15px;
        font-weight: 600;
        top: 0px;
        right: 0px;
        background: #F5F8FF;
        overflow-y: scroll;
        margin: 20px 0;
        flex: 1;
        position: relative;

        &_body{
            position: relative;
            overflow-y: hidden;
            // border: 1px solid red;
        }

        .cancel{
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
        }

        h3{
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 21px;
        }

        p{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
        }

        .text1{
            color: #818181;
            span{
                color: #161616;
                margin-left: auto;
                float: right;
            }
        }
        
        .open{
            font-size: 0.9em;
            border: 1px solid rgba(196, 32, 92, 0.19);
            color: #D1373F;
            font-weight: 600;
            border-radius: 25px;
            background: rgba(196, 32, 92, 0.19);
            text-align: center;
            padding: 2px 10px;
            display: flex;
            align-items: center;
            cursor: default;

            div{
                background: #D1373F;
                width: 5px;
                margin-right: 4px;
                height: 5px;
                font-size: 2em;
                border-radius: 50%;
            }
        }

        .closed{
            font-size: 0.9em;
            border: 1px solid #ECFDF3;
            color: #027A48;
            font-weight: 600;
            border-radius: 25px;
            background: #ECFDF3;
            text-align: center;
            padding: 2px 10px;
            cursor: default;
            display: flex;
            align-items: center;

            div{
                background: #027A48;
                width: 5px;
                margin-right: 4px;
                height: 5px;
                font-size: 2em;
                border-radius: 50%;
            }
        }

        &-code{
            h5{
                color: #0F123F;
                font-size: 1.1em;
                margin: 50px 0 10px;
            }
            pre{
                border: 1px solid #EAECF0;
                border-radius: 10px;
                padding: 10px;
            }
        }
    }

    &_flex{
        display: flex;
    }

    &_block{
        display: block;
    }

    &_sixty{
        width: 60%;
    }

    &_hundred{
        width: 100%;
    }

    &_apiService{
        padding: 2rem;
        .new{
            display: flex;
            width: 280px;
            height: 70px;
            background: #FFFFFF;
            border: 1px solid #0046E6;
            border-radius: 10px;
            align-items: center;
            padding: 0.2rem 1rem;
            cursor: pointer;

            h4{
                font-weight: 700;
                font-size: 20px;
                line-height: 26px;
                margin-left: 1rem;
            }
        }

        .main{
            display: flex;
            // justify-content: center;
            padding: 3rem 0;
            flex-wrap: wrap;

            &_box{
                background: #FFFFFF;
                box-shadow: 0px 13.3657px 44.5523px rgba(160, 163, 189, 0.5);
                border-radius: 8.91046px;
                width: 466px;
                margin: 1rem 1rem 1rem 0;
            
                &-top{
                    display: flex;
                    justify-content: space-between;
                    padding: 1rem;
                    border-bottom: 1.5px solid #F2F4F7;

                    &-title{
                        display: flex;
                        align-items: center;

                        h5{
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 26px;
                            margin-left: 0.4rem;
                            margin-bottom: 0;
                        }
                    }

                    &-refresh{
                        display: flex;
                        align-items: center;
                        width: 156px;
                        height: 32px;
                        background: $color-sidebar;
                        border: 1px solid #98A2B3;
                        border-radius: 7.5px;
                        padding: 0 0.4rem;
                        cursor: pointer;
                        
                        p{
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 23px;
                            color: #FFFFFF;
                            margin: 0 0.5rem;
                        }
                    }
                }

                &-body{
                    display: flex;
                    padding: 2rem 1rem;
                    align-items: center;

                    h5{
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 21px;
                        margin-right: 1rem;
                    }
                    
                    .dataKey{
                        position: relative;
                        input{
                            background: #FFFFFF;
                            border: 1px solid #D0D5DD;
                            width: 305px;
                            height: 37px;
                            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                            border-radius: 8px;
                            margin-right: 0.8rem;
                        }
                        img{
                            position: absolute;
                            right: 20px;
                            top: 12px;
                            cursor: pointer
                        }
                    }

                    
                }

            }
        }
    }
}