.myModal{
    // height: 100vh;

    &_blurBg{
        // position: relative;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: -50px;
        bottom: -50px;
        z-index: 3;
        background: rgba(4, 4, 4, 0.77);
        // backdrop-filter: blur(16px);
    }

    &_main{
        width: 400px;
        // border: 1px solid #0046E6;
        background: white;
        border-radius: 10px;
        z-index: 3;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // top: 10vh;
        // left: calc(50% - 173px);
        // padding: 10px;
        /* overflow-y: scroll; */
        @media (max-width: 560px) {
            width: 300px;
            
        }

        &_privacy {
            max-width: 400px;
             max-height: 600px;
            border: 1px solid #0046E6;
            background: white;
            border-radius: 10px;
            z-index: 3;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            // top: 10vh;
            // left: calc(50% - 173px);
            padding: 10px;
            margin: 10px;
            overflow-y: scroll;

            .top {
                display: flex;
                justify-content: space-between;
                h3{
                    padding: 20px;
                }

                h1 {
                    font-weight: 600;
                    font-size: 17.9141px;
                    line-height: 31px;
                    align-items: center;
                    color: #00072B;
                }
            }

            table {
                width: 350px !important;
                text-align: center;
            }
        }

        &-widget{
            margin: 20px;

            &-header {
                display: flex;
                align-items: center;
                justify-content: center;
                // padding: 20px 0;

                p {
                    font-family: 'DM Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 35px;
                    margin: 0 20px 0 0;
                    /* flex: 1.5; */
                    text-align: start;
                    width: 60%
                }
            }
            &-title{
                font-size: 1em;
                text-align: center;
                font-weight: 700;
            }

            &-upload{
                display: flex;
                justify-content: space-between;
                box-sizing: border-box;
                /* background: #E3E4E7; */
                padding: 4px 20px 4px 10px;
                width: 100%;
                margin: 30px 0;
                border-radius: 8px;
                border: 1px solid #E3E4E7;

                img{
                    width: 30px;
                }
                p{
                    margin: 3px 0 0 10px;
                }
            }

            &-share {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin: 0;
                img {
                    width: 75px;
                    height: 75px;
                    margin-bottom: 20px;
                    @media (max-width: 560px) {
                        width: 75px;
                        height: 75px;
                        margin-bottom: 10px;
                    }
                }
                h4 {
                    font-family: 'DM Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 27px;
                    line-height: 35px;
                    @media (max-width: 560px) {
                        font-size: 20px;
                        line-height: 28px;
                    }
                }
                p {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 26px;
                    @media (max-width: 560px) {
                        font-size: 12px;
                        line-height: 20px;
                    }
                }

                &-action {
                    display: flex;
                    justify-content: space-around;
                    .cancel {
                        background: #D0D0D0;
                          border: 1px solid #D0D0D0;
                          padding: 5px 20px;
                          border-radius: 7px;
                          color: white;
                          margin-left: auto;
                    }

                    .share {
                        background: #0046E6;
                          border: 1px solid #0046E6;
                          padding: 5px 20px;
                          border-radius: 7px;
                          color: white;
                    }
                }

                &-kyc {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    button{
                    background: #0046E6;
                    border: 1px solid #0046E6;
                    margin-left: auto;
                    width: 100%;
                    color: #fff;
                    padding: 5px;
                    border-radius: 7px;
                     }
                    p{
                        font-size: 16px;;
                        margin: 16px 0;
                        color: #0046E6;
                        font-style: normal;
                        font-weight: 700;
                        letter-spacing: 0.5px;
                        cursor: pointer;
                        @media (max-width: 560px) {
                            font-size: 12px;
                            margin: 12px 0;
                        }

                        a {
                            text-decoration: none;
                        }

                    }

                    .link {
                        background: #0046E6;
                        border: 1px solid #0046E6;
                        padding: 8px 24px;
                        border-radius: 7px;
                        color: white;
                        width: 200px !important;
                        font-size: 12px;
                    }
                }

                &-icons{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin: 15px 0;
                }

                &-tick{
                    display: flex;
                    align-items: center;
                    justify-content:center;

                    .tick{
                        width: 20px ;
                        height: 20px;
                        margin: 0;
                    }
                    h6{
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 26px;
                        margin: 0 10px;
                    }
                }
            }

            &-form{
                width: 100%;
                box-sizing: border-box;
                margin: 0 0 30px;
                
                input{
                    width: 100%;
                    margin: 10px 0;
                    padding: 5px 15px;
                    height: 40px;
                    border: 2px solid #C4C4C4;
                    border-radius: 5px;
                    color: $color-formInput;
                }
            }

            &-action{
                display: flex;
                .form-submit {
                    background: #0046E6;
                    border: 1px solid #0046E6;
                    margin-left: auto;
                    width: 100%;
                }

                button{
                    padding: 5px 20px;
                    border-radius: 7px;
                    color: white;
                }

                .cancel{
                    background: $color-btnInactive;
                    border: 1px solid $color-btnInactive;
                }

                .publish{
                    background: $color-sidebar;
                    border: 1px solid $color-sidebar;
                    margin-left: auto;
                }
                &-close{
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;

                    button{
                        padding: 5px 20px;
                        border-radius: 7px;
                        color: white;
                    }

                    .close{
                        background: #D0D0D0;
                        border: 1px solid #D0D0D0;
                        margin: 0 0 20px 0;
                    }
                }
            }

            &-bottom{
                margin: 20px 0;
            }
        }

        &-linkBank{

            &-formflag{
                position: relative;
                img{
                    position: absolute;
                    bottom: 77px;
                    width: 25px;
                }
                input{
                    padding: 5px 5px 5px 30px !important;
                    box-sizing: border-box;
                }
            }
            input{
                border-top: 1px solid transparent;
                border-left: 1px solid transparent;
                border-right: 1px solid transparent;
                border-bottom: 1px solid #757575;
                color: #757575;
                font-size: 1em;
                width: 100%;
                padding: 5px;
                margin: 30px 0;

                &:focus{
                    outline: none;
                }
            }

            &-toggle{
                display: flex;
                margin: 10px 0 30px;
                &-btn{
                    margin-left: auto;
                }
            }
        }

        &-shareReport{
            input{
                border-top: 1px solid transparent;
                border-left: 1px solid transparent;
                border-right: 1px solid transparent;
                border-bottom: 1px solid #757575;
                color: #757575;
                font-size: 1em;
                width: 100%;
                padding: 5px;
                margin: 0 0 15px;

                &:focus{
                    outline: none;
                }
            }
        }

        &_password{
            input{
                width: 100%;
                border: 1px solid #DADADA;
                border-radius: 4px;
                color: #757575;
                font-size: 1em;
                width: 100%;
                padding: 5px;
                margin: 5px 0 15px;
            }
        }

        
        &-delete{
           text-align: center;
           padding: 2em 1em 0 1em;

           h4{
            margin-top: 20px;
            font-weight: 500;
            font-size: 22px;
            line-height: 29px;
        }

            &-top{
                padding: 1em 3em;

                label{
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 26px;
                    margin-right: 15px;
                }
            }
            &-bottom{
                padding: 1em 2em 2em 2em;
                display: flex;
                justify-content: space-between;

                .cancel{
                    border: 1px solid #D0D0D0;
                    padding: 5px 20px;
                    color: #333333;
                    background: #FFFFFF;
                    border: 1px solid #000000;
                    border-radius: 8px;
                    font-weight: 700;
                    font-size: 16px;
                    letter-spacing: 0.5px;
                    width: 112px;
                }

                .publish{
                    background: #D92D20;
                    /* Error/600 */
                    color: #fff;
                    border: 1px solid #D92D20;
                    /* Shadow/xs */
                    width: 170px;
                    height: 44px;
                    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                    border-radius: 8px;

                }
                .proceed{
                    background: #0046E6;
                    border: 1px solid #0046E6;
                    margin-left: auto;
                    padding: 10px;
                    border-radius: 7px;
                    color: white;
                    width: 112px;
                }
            }
        }
        
        &-biz{
           text-align: center;
           padding: 2em 1em 0 1em;

           h4{
            margin-top: 20px;
            font-weight: 500;
            font-size: 22px;
            line-height: 29px;
        }

        &-flex{
            display: flex;
            flex-direction: column;
            margin-top: 1rem;
        }

            &-top{
                padding: 1em 3em;

                label{
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 26px;
                    margin-right: 15px;
                }
            }
            &-bottom{
                padding: 1em 2em 2em 2em;
                display: flex;
                justify-content: space-between;

                .cancel{
                    border: 1px solid #D0D0D0;
                    padding: 5px 20px;
                    color: #333333;
                    background: #FFFFFF;
                    border: 1px solid #000000;
                    border-radius: 8px;
                    font-weight: 700;
                    font-size: 16px;
                    letter-spacing: 0.5px;
                    width: 112px;
                }

                .publish{
                    background: #D92D20;
                    /* Error/600 */
                    color: #fff;
                    border: 1px solid #D92D20;
                    /* Shadow/xs */
                    width: 170px;
                    height: 44px;
                    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                    border-radius: 8px;

                }
                .proceed{
                    background: #0046E6;
                    border: 1px solid #0046E6;
                    margin-left: auto;
                    padding: 10px;
                    border-radius: 7px;
                    color: white;
                    width: 112px;
                }
            }
        }
    }

    &_mainInvite{
        width: 600px;
        // border: 1px solid #0046E6;
        background: white;
        border-radius: 10px;
        z-index: 3;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // top: 10vh;
        // left: calc(50% - 173px);
        // padding: 10px;
        /* overflow-y: scroll; */
        @media (max-width: 560px) {
            width: 300px;
            
        }


        &-invite{
            h4{
                margin-top: 20px;
                font-weight: 500;
                font-size: 22px;
                line-height: 29px;
            }

            &-top{
                padding: 1em 3em;

                label{
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 26px;
                    margin-right: 15px;
                }
            }
            &-bottom{
                padding: 0.7em 3em 3em 3em;
                display: flex;
                justify-content: space-between;

                .cancel{
                    border: 1px solid #D0D0D0;
                    padding: 5px 20px;
                    color: #333333;
                    background: #FFFFFF;
                    border: 1px solid #000000;
                    border-radius: 8px;
                    font-weight: 700;
                    font-size: 16px;
                    letter-spacing: 0.5px;
                    width: 112px;
                }

                .publish{
                    background: #0046E6;
                    border: 1px solid #0046E6;
                    margin-left: auto;
                    padding: 12px 24px;
                    border-radius: 7px;
                    color: white;

                }
            }
        }
       
    }

    &_custom{
        width: 450px;
        background: white;
        border-radius: 10px;
        z-index: 3;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media (max-width: 560px) {
            width: 300px;
            
        }

        &-invite{
            h4{
                margin: 20px;
                font-weight: 500;
                font-size: 20px;
                line-height: 29px;
            }

            &-top{
                padding: 0.8em;

                label{
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 26px;
                    margin-right: 15px;
                }
                &_profile{
                    &-form{
                        padding: 2em 5em;
            
                        &-div{
                            display: flex;
                            align-items: center;
                            justify-content: center;
            
                            .input{
                                // width: 326px;
                                height: 40px;
                                border: 1px solid #DADADA;
                                color: $color-formInput;
                                padding: 8px 10px;
                                margin: 10px 0;
                                border-radius: 7px;
                                display: flex;
                                align-items: center;
            
                                input{
                                    border: none;
                                    // margin-left: 10px;
                                    width: 100%;
                                }
                            }
                            
                        }
                        label{
                           
                            font-weight: 500;
                            font-size: 21px;
                            line-height: 27px;
                        }
            
                        &-btn1{
                            width: 180px !important;
                            border: 1px solid #DADADA !important;
                            color: #2E2E2E !important;
                            font-size: 0.9em;
                            background-color: white !important;
                            margin: 5px 0 0 !important;
                        }
                        
                    }
                }
            }
            &-bottom{
                padding: 1em 0;
                display: flex;
                // justify-content: space-between;
                width: 300px;

                .cancel{
                    border: 1px solid #D0D0D0;
                    padding: 2px;
                    color: #333333;
                    background: #FFFFFF;
                    border: 1px solid #000000;
                    border-radius: 8px;
                    font-weight: 500;
                    font-size: 16px;
                    letter-spacing: 0.5px;
                    width: 112px;
                }

                .publish{
                    background: #0046E6;
                    border: 1px solid #0046E6;
                    margin-left: auto;
                    padding: 10px;
                    border-radius: 7px;
                    color: white;
                    width: 112px;
                }
            }
        }

        &_permission{
            width: 400px;
            padding: 10px 20px 20px 20px;
    
            p{
                
                span{
                background-color: aliceblue;
                padding: 4px 12px;
                border-radius: 10px;
                float: right;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                margin-top: -8px;
                color: #0046E6;
                cursor: pointer;
                }
            }
            @media(max-width: $mobile){
                width: 100%;
            }
    
            label{
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 10px;
            }
    
            // button{
            //     margin-top: 10px;
            //     width: 100px;
            //     height: 40px;
            //     color: #FFFFFF;
            //     background: #0046E6;
            //     border-radius: 8px;
            //     border: 0;
            //     font-weight: 500;
            // }
    
           
        }
       
    }


    &_newshare{
        width: 400px;
        border-radius: 10px;
        z-index: 3;
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media (max-width: 560px) {
            width: 300px;
        }

        &-content{
            background-color: white;
            &-flex{
                display: flex;
                justify-content: space-around;
               

                p{
                    width: 50%;
                    text-align: center;
                    font-size: 1.3em;
                    font-weight: 600;
                    margin: 0;
                    padding: 10px 0;
                    cursor: pointer;
                    border-radius: 20px 20px 0 0;
                }

                .notactive{
                    background-color: black;
                    color: white;
                }

                .active{
                    background-color: white;
                }
            }

            &-wa{
                padding: 30px 30px 40px;
                box-sizing: border-box;
                background-color: white;
                border-radius: 0 0 10px 10px;

                .mail{
                    padding: 7px 15px;
                }
                label{
                    margin: 0 0 10px;
                }
                input{
                    width: 100%;
                    border: 1px solid #DADADA;
                    border-radius: 4px;
                }
            }

        }

    }

    &_team{
        width: 612px;
        border-radius: 14px;
        z-index: 3;
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media (max-width: 560px) {
            width: 300px;
        }

        &-content{
            background-color: white;
            
            box-sizing: border-box;
            background-color: white;
            border-radius: 0 0 10px 10px;
            padding: 30px 0;

            &-header{
                padding: 0 30px 30px;

                h4{
                    font-weight: 700;
                    font-size: 27.9644px;
                    line-height: 36px;
                    color: #000000;
                    margin-bottom: 1em;
                }
            }
               

              
               

                &-input{
                    width: 100%;
                    border: 1px solid #DADADA;
                    border-radius: 4px;
                    padding: 5px;
                }

                &-div{
                    width: 100%;

                   
                    

                       &-container{

                           padding: 20px 30px;
                           border-top: 1px solid #D2D2D2;
                           border-bottom: 1px solid #D2D2D2;

                           p{
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 23px;
                        }

                           input{
                               margin-right: 10px;
                           }
                       }
                  
                }
            

            &-wa{
                padding: 30px 30px 40px;
                box-sizing: border-box;
                background-color: white;
                border-radius: 0 0 10px 10px;

                .mail{
                    padding: 7px 15px;
                }
                label{
                    margin: 0 0 10px;
                }
               
            }

        }

    }
}