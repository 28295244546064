.auth{
    // padding: 30px 0;
    width: 100%;
    box-sizing: border-box;

    &_layout{
        box-sizing: border-box;
        @media(min-width: $tab-land){
            display: flex;
            position: relative;
            width: 100%;
        }
    }
    &_section1{
        width: 50vw;
        // border: 1px solid red;
        margin-bottom: 50px;
        @media(max-width: $tab-land){
            width: 100vw;
            height: 100vh;
        }
        @media(max-width: $mobile){
            padding: 0 20px;
        }

        &_container{
            max-width: 450px;
            margin: auto;
            // border: 1px solid blue;
            display: block;

            &-title{
                color: $color-grey;
                font-weight: 700;
            }
        }

        &_logo{
            width: 200px;
            margin: 70px 0 30px;
            @media(max-width: $mobile){
                margin: 30px 0 30px;
            }
        }
    }
    &_section2{
        background-color: $color-sidebar;
        width: 50vw;
        position: fixed;
        right: 0;
        height: 100vh;

        @media(max-width: $tab-land){
            display: none;
        }

        &-pattern{
            // width: 70%;
            position: absolute;
            top: 55%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            

            img{
                border: 14px solid rgba(48, 111, 255, 0.69);
            border-radius: 24px;
            }

            h3{
                font-weight: 700;
                font-size: 29.5556px;
                line-height: 38px;
                color: #FFFFFF;
                margin-top: 1em;
            }

            p{
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-align: center;

                color: #FFFFFF;
            }
           
        }
    }

    &_signup{
        margin-top: 100px;
        box-sizing: border-box;

        h3{
            font-weight: 700;
            font-size: 29.5556px;
            line-height: 38px;
            color: #3C3C3C;
        }

        p{
            font-weight: 500;
            font-size: 14.56px;
            line-height: 19px;
            margin-bottom: 1.5em;
            color: #808080;
        }

        label{
            width: 100%;
            font-weight: 500;
            font-size: 14.56px;
            line-height: 19px;
            margin-bottom: 0.5em;
            /* identical to box height */


            color: #4E4E4E;
        }

        img{
            margin-bottom: 1.5em;
        }

        input{
            width: 100%;
            margin: 10px 0;
            padding: 5px 15px;
            height: 40px;
            border: 2px solid #C4C4C4;
            border-radius: 5px;
            color: $color-formInput;

            &:focus{
                border: 2px solid $color-primary;
                outline: none !important;
            }
        }
        
        &-phone{
            appearance: none;
        }

        option{
            padding: 10px 15px;
            // background: red;
            font-size: 2em;
        }

        &-password{
            position: relative;
            img{
                position: absolute;
                right: 20px;
                width: 20px;
                cursor: pointer;
            }
            .notvisible{
                top: 50%;
            }
            .visible{
                top: 45%;
            }
        }

        &-forgot{
            color: #0046E6 !important;
            margin-top: 10px;

        }

        &-submit{
            width: 100%;
            padding: 10px 0;
            text-align: center;
            margin: 10px 0;
            border-radius: 7px;
            border: 1px solid $color-btnInactive;
            background: $color-btnInactive;
            font-size: 1.1em;
            font-weight: 600;
            color: white;
        }

        &-active{
            width: 100%;
            padding: 10px 0;
            text-align: center;
            margin: 10px 0;
            border-radius: 7px;
            border: 1px solid $color-primary;
            background: $color-primary;
            font-size: 1.1em;
            font-weight: 600;
            color: white;
        }

        &-text1{
            text-align: center;
            margin-top: 10px;
            color: #292929;
            span{
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    // &_success{
    //     width: 100vh;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     flex-direction: column;
    // }

    &_otp{
        position: relative;
        margin-top: 40px;
        input{
            margin: 10px 0px 10px 0;
            padding: 10px 15px;
            border: 2px solid #C4C4C4;
            border-radius: 5px;
            width: 50px !important; 
            height: 45px !important; 
            color: $color-formInput;
            
            @media(max-width: 450px){
                width: 12% !important; 
                height: 40px !important; 
                margin: 10px 15px 10px 0 !important;
                padding: 5px !important;
            }

            @media(max-width: 400px){
                margin: 10px 10px 10px 0 !important;
            }

            &:focus{
                border: 2px solid $color-primary;
                outline: none !important;
            }
        }

        &-show{
            width: 20px;
            position: absolute;
            top: 25px;
            right: 17px;
            cursor: pointer;

            @media(max-width: 450px){
            top: 20px;
            right: 0;
            }
        }

        &-text1{
            span{
                color: $color-primary;
                cursor: pointer;
            }
        }
    }
}